import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState, memo } from "react";
import { css } from "@emotion/react";
import LoadingIcon from "../LoadingIcon";
import { fetchEmbed, loadIframely } from "../../services/iframely";
import { logError } from "../../services/logger";
function isIframelyError(err) {
    return !!(err === null || err === void 0 ? void 0 : err.status);
}
const Iframely = ({ url }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [html, setHtml] = useState({
        __html: "<div />",
    });
    useEffect(() => {
        const fetchIframely = async () => {
            try {
                const res = await fetchEmbed(url);
                const data = await res.json();
                setIsLoaded(true);
                if (data.html) {
                    setHtml({ __html: data.html });
                }
                else if (data.error) {
                    setError({ status: data.status, error: data.error });
                }
            }
            catch (e) {
                if (isIframelyError(e)) {
                    setError(e);
                }
                else {
                    logError({ message: "Error fetching Iframely API.", error: e });
                    setError({ error: "Unknown error" });
                }
            }
            finally {
                setIsLoaded(true);
            }
        };
        fetchIframely();
    }, []);
    useEffect(() => {
        loadIframely();
    });
    if (error) {
        return (_jsxs("div", { children: ["Error: ", error.status, " - ", error.error] }));
    }
    if (!isLoaded) {
        return (_jsx("div", { css: css `
          text-align: center;
        `, children: _jsx(LoadingIcon, {}) }));
    }
    return _jsx("div", { dangerouslySetInnerHTML: html });
};
export default memo(Iframely);

export const forma = {
    id: 984122,
    name: "Forma",
    network: "forma",
    nativeCurrency: {
        symbol: "TIA",
        name: "TIA",
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http: ["https://rpc.forma.art"],
            webSocket: ["wss://rpc.forma.art"],
        },
        public: {
            http: ["https://rpc.forma.art"],
            webSocket: ["wss://rpc.forma.art"],
        },
    },
    blockExplorers: {
        default: {
            name: "Forma Explorer",
            url: "https://explorer.forma.art",
        },
    },
    contracts: {
        multicall3: {
            address: "0xC5402e0BAF74c1042D72749cB8cA78c58dD93D6f",
            blockCreated: 1289867,
        },
    },
};
export const scroll = {
    id: 534352,
    name: "Scroll",
    network: "scroll",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://rpc.scroll.io"],
            webSocket: ["wss://wss-rpc.scroll.io/ws"],
        },
        public: {
            http: ["https://rpc.scroll.io"],
            webSocket: ["wss://wss-rpc.scroll.io/ws"],
        },
    },
    blockExplorers: {
        default: {
            name: "Scrollscan",
            url: "https://scrollscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 14,
        },
    },
};

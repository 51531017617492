var SupportedMediaMime;
(function (SupportedMediaMime) {
    SupportedMediaMime["GIF"] = "GIF";
    SupportedMediaMime["JPEG"] = "JPEG";
    SupportedMediaMime["MP3"] = "MP3";
    SupportedMediaMime["MP4"] = "MP4";
    SupportedMediaMime["PNG"] = "PNG";
    SupportedMediaMime["WAV"] = "WAV";
    SupportedMediaMime["WEBM"] = "WEBM";
})(SupportedMediaMime || (SupportedMediaMime = {}));
var SupportedMediaType;
(function (SupportedMediaType) {
    SupportedMediaType["AUDIO"] = "AUDIO";
    SupportedMediaType["IMAGE"] = "IMAGE";
    SupportedMediaType["VIDEO"] = "VIDEO";
})(SupportedMediaType || (SupportedMediaType = {}));
var AttachmentType;
(function (AttachmentType) {
    AttachmentType["MEDIA"] = "MEDIA";
    AttachmentType["TOKEN"] = "TOKEN";
})(AttachmentType || (AttachmentType = {}));
export { AttachmentType, SupportedMediaType };

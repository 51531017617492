import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useRef } from "react";
/**
 * This component is similar to "dangerouslySetInnerHtml", but it also
 * allows script execution.
 */
const VeryDangerouslySetHtml = ({ html, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
dangerouslySetInnerHTML, ...rest }) => {
    // We remove 'dangerouslySetInnerHTML' from props passed to the div
    const divRef = useRef(null);
    useEffect(() => {
        if (!divRef.current) {
            return;
        }
        // Create a DocumentFragment and parse the HTML string
        const slotHtml = document.createRange().createContextualFragment(html);
        // Clear the container
        divRef.current.innerHTML = "";
        // Append the new content
        divRef.current.appendChild(slotHtml);
    }, [html, divRef]);
    return _jsx("div", { ...rest, ref: divRef });
};
export default memo(VeryDangerouslySetHtml);

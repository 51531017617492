import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useCallback, useEffect } from "react";
import { css } from "@emotion/react";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createPortal } from "react-dom";
import { Link as LinkIcon } from "../../../../../../assets/icons.generated";
import Text from "../../../../../Typography/Text";
import Tooltip from "../../../../../Tooltip";
import SimpleFloatingLinkEditor from "../../../components/SimpleFloatingLinkEditor";
import { controlOrMeta } from "../../../utils";
import { textStylesButton } from "../styles";
const isLinkEvent = (event) => {
    const { keyCode, ctrlKey, metaKey, altKey } = event;
    return keyCode === 75 && !altKey && controlOrMeta(metaKey, ctrlKey);
};
const LinkInsert = ({ selectedElement, anchorElem, dark = false, }) => {
    const [activeEditor] = useLexicalComposerContext();
    const formatLink = useCallback(() => {
        if (!selectedElement.isLink) {
            activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, "");
        }
        else {
            activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [activeEditor, selectedElement.isLink]);
    const keyDownHandler = useCallback((event) => {
        if (isLinkEvent(event)) {
            formatLink();
            event.preventDefault();
        }
    }, [formatLink]);
    useEffect(() => {
        return activeEditor.registerRootListener((rootElement, prevRootElement) => {
            if (prevRootElement !== null) {
                prevRootElement.removeEventListener("keydown", keyDownHandler);
            }
            if (rootElement !== null) {
                rootElement.addEventListener("keydown", keyDownHandler);
            }
        });
    }, [activeEditor, keyDownHandler]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { padding: "xsmall", content: _jsx(Text, { as: "span", size: "xsmall", children: "Link" }), children: _jsx("button", { css: textStylesButton(dark, selectedElement.isLink), onClick: formatLink, "aria-label": "Insert Link", children: _jsx(LinkIcon, { css: css `
              width: 16px;
              height: 16px;
            ` }) }) }), selectedElement.isLink &&
                createPortal(_jsx(SimpleFloatingLinkEditor, { editor: activeEditor, anchorElem: anchorElem }), anchorElem || document.body)] }));
};
export default memo(LinkInsert);

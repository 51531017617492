import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { ArbitrumLogo, BaseLogo, Beaker02 as TestnetLogo, CurrencyEthereum, CurrencyMatic, Degen as DegenLogo, Higher as HigherLogo, Grnd as GrndLogo, EthereumLogo, OptimismLogo, ZoraLogo, EnjoyLogo, Forma as FormaLogo, Mantle as MantleLogo, Scroll as ScrollLogo, } from "@hl/base-components/lib/assets/icons.generated";
import { BLUE_COLOR, GREEN_COLOR, PRIMARY_COLOR, VIOLET_COLOR, } from "@hl/base-components/lib/theme/button";
import { CurrencySymbol, CurrencyType, } from "../apollo/graphql.generated";
const getNativeCurrencyIcon = (platform, size) => {
    const chain = platform.toLowerCase();
    if (chain.includes("sepolia") ||
        chain.includes("goerli") ||
        chain.includes("mumbai"))
        return _jsx(TestnetLogo, { height: size, width: size });
    if (chain.includes("polygon"))
        return _jsx(CurrencyMatic, { height: size, width: size });
    if (chain.includes("ethereum"))
        return _jsx(EthereumLogo, { height: size, width: size });
    if (chain.includes("arbitrum"))
        return _jsx(ArbitrumLogo, { height: size, width: size });
    if (chain.includes("base"))
        return _jsx(BaseLogo, { height: size, width: size });
    if (chain.includes("optimism"))
        return _jsx(OptimismLogo, { height: size, width: size });
    if (chain.includes("zora"))
        return _jsx(ZoraLogo, { height: size, width: size });
    if (chain.includes("forma"))
        return _jsx(FormaLogo, { height: size, width: size });
    if (chain.includes("mantle"))
        return _jsx(MantleLogo, { height: size, width: size });
    if (chain.includes("scroll"))
        return _jsx(ScrollLogo, { height: size, width: size });
    return _jsx(CurrencyEthereum, { height: size, width: size });
};
const getNativeCurrencyName = (platform) => {
    const chain = platform.toLowerCase();
    if (chain.includes("polygon") || chain.includes("mumbai"))
        return "Matic";
    if (chain.includes("eth") ||
        chain.includes("arbitrum") ||
        chain.includes("optimism") ||
        chain.includes("zora") ||
        chain.includes("scroll"))
        return "Eth";
    if (chain.includes("goerli") ||
        chain.includes("arbitrum-goerli") ||
        chain.includes("optimism-goerli") ||
        chain.includes("base-goerli"))
        return "Goerli Eth";
    if (chain.includes("sepolia") || chain.includes("base-sepolia"))
        return "Sepolia Eth";
    if (chain.includes("base"))
        return "Base";
    if (chain.includes("zora-goerli"))
        return "GöETH";
    if (chain.includes("forma"))
        return "Tia";
    if (chain.includes("mantle"))
        return "Mnt";
    return "Unknown";
};
const getCurrencySymbol = (chainId, currencySymbol, camelcase) => {
    switch (chainId.toString()) {
        case "1":
            return camelcase ? "Eth" : "ETH";
        case "5":
            return camelcase ? "GoerliEth" : "GoerliETH";
        case "11155111":
            return camelcase ? "SepoliaEth" : "SepoliaETH";
        case "137":
        case "80001":
            return camelcase ? "Matic" : "MATIC";
        case "42161":
            return camelcase ? "Eth" : "ETH";
        case "421613":
            return camelcase ? "GoerliEth" : "AGOR";
        case "10":
            return camelcase ? "Eth" : "ETH";
        case "420":
            return camelcase ? "GoerliEth" : "GoerliETH";
        case "8453":
            if (currencySymbol === CurrencySymbol.DEGEN) {
                return camelcase ? "$degen" : "DEGEN";
            }
            if (currencySymbol === CurrencySymbol.HIGHER) {
                return camelcase ? "$higher" : "HIGHER";
            }
            if (currencySymbol === CurrencySymbol.GRND) {
                return camelcase ? "$grnd" : "GRND";
            }
            return camelcase ? "Eth" : "ETH";
        case "84531":
            return camelcase ? "GoerliEth" : "GoerliETH";
        case "84532":
            if (currencySymbol === CurrencySymbol.DEGEN) {
                return camelcase ? "$degen" : "DEGEN";
            }
            if (currencySymbol === CurrencySymbol.HIGHER) {
                return camelcase ? "$higher" : "HIGHER";
            }
            return camelcase ? "SepoliaEth" : "SepoliaETH";
        case "7777777":
            if (currencySymbol === CurrencySymbol.ENJOY) {
                return camelcase ? "$enjoy" : "ENJOY";
            }
            return camelcase ? "Eth" : "ETH";
        case "999":
            return camelcase ? "GöEth" : "GöETH";
        case "984122":
            return camelcase ? "Tia" : "TIA";
        case "5000":
            return camelcase ? "Mnt" : "MNT";
        case "534352":
            return camelcase ? "Eth" : "ETH";
        default:
            return camelcase ? "Unknown" : "UNKNOWN";
    }
};
const getCurrencyColor = (currency, chainId) => {
    switch (chainId) {
        case 8453:
        case 84532:
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.DEGEN) {
                return VIOLET_COLOR;
            }
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.HIGHER) {
                return GREEN_COLOR;
            }
            return PRIMARY_COLOR;
        case 7777777:
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.ENJOY) {
                return BLUE_COLOR;
            }
            return PRIMARY_COLOR;
        default:
            return PRIMARY_COLOR;
    }
};
const getERC20CurrencyIcon = (currency, chainId, size = 20) => {
    if ((currency === null || currency === void 0 ? void 0 : currency.type) === CurrencyType.NATIVE) {
        return null;
    }
    switch (chainId) {
        case 8453:
        case 84532:
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.DEGEN) {
                return _jsx(DegenLogo, { height: size, width: size });
            }
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.HIGHER) {
                return _jsx(HigherLogo, { height: size, width: size });
            }
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.GRND) {
                return _jsx(GrndLogo, { height: size, width: size });
            }
            return null;
        case 7777777:
            if ((currency === null || currency === void 0 ? void 0 : currency.symbol) === CurrencySymbol.ENJOY) {
                return _jsx(EnjoyLogo, { height: size, width: size });
            }
            return null;
    }
    return null;
};
const getERC20Links = (currency, chainId) => {
    if (currency.type === CurrencyType.NATIVE) {
        return null;
    }
    switch (chainId) {
        case 8453:
        case 84532:
            if (currency.symbol === CurrencySymbol.DEGEN) {
                return {
                    currencyUrl: "https://www.degen.tips",
                    bridgeUrl: "https://bridge.degen.tips/",
                };
            }
            if (currency.symbol === CurrencySymbol.HIGHER) {
                return {
                    currencyUrl: "https://www.higher.party/",
                    bridgeUrl: "https://www.higher.party/buy",
                };
            }
            if (currency.symbol === CurrencySymbol.GRND) {
                return {
                    currencyUrl: "https://www.undrgrnd.io/grnd",
                    bridgeUrl: "https://app.uniswap.org/explore/tokens/base/0xD94393cd7fCCeb749cD844E89167d4a2CDC64541",
                };
            }
            return null;
        case 7777777:
            if (currency.symbol === CurrencySymbol.ENJOY) {
                return {
                    currencyUrl: "https://www.enjoy.tech/",
                    bridgeUrl: "https://www.coingecko.com/en/coins/enjoy",
                };
            }
            return null;
    }
    return null;
};
export { getCurrencySymbol, getNativeCurrencyName, getNativeCurrencyIcon, getERC20CurrencyIcon, getCurrencyColor, getERC20Links, };

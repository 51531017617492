import { makeVar } from "@apollo/client";
const defaultLayoutContext = {
    theme: undefined,
};
export const layoutContext = makeVar(defaultLayoutContext);
export const resetLayoutContextVar = () => {
    layoutContext(defaultLayoutContext);
};
export const updateLayoutContext = (data) => {
    layoutContext({ ...layoutContext(), ...data });
};

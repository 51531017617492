import { memo, PropsWithChildren } from "react";

import { Helmet } from "react-helmet-async";

export type AppSEOProps = {
  title?: string;
  description?: string;
};

export const AppSEO = memo(
  ({ title, description, children }: PropsWithChildren<AppSEOProps>) => {
    return (
      <Helmet>
        <title>{`${title ?? "⎄"} · Powered by Highlight`}</title>
        <meta name="author" content="Highlight" />
        {description && <meta name="description" content={description} />}
        {children}
      </Helmet>
    );
  }
);

import { useCallback, useEffect, useState, ChangeEvent } from "react";

import {
  Attachment,
  AttachmentType,
  SupportedMediaType,
} from "@hl/communities-app/lib/apollo/graphql.generated";
import { logError } from "@hl/shared-features/lib/services/logger";
import { $isLinkNode } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType } from "@lexical/utils";
import type { SerializedLexicalNode } from "lexical";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  ElementNode,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  SerializedEditorState,
  TextNode,
} from "lexical";

import {
  PageAttachmentType,
  PageMediaMetadata,
  PageSupportedMediaMime,
  PageSupportedMediaType,
} from "apollo/graphql.generated";
import { DEFAULT_ERROR_MESSAGE } from "config";
import { formatsByType, mimeFromType, sizeByType } from "utils/media/file";

import { GetPageUploadUrlMutation } from "./editor.graphql.generated";

export type EditorNodes = SerializedLexicalNode & {
  tokenId: string;
  attachmentType: PageAttachmentType.MEDIA;
  attachmentName?: string;
  type: string;
  version: string;
  mediaMime: PageSupportedMediaMime;
  mediaType: PageSupportedMediaType;
  mediaUrl?: string;
};

const CreateAttachmentMock = (
  mediaType: SupportedMediaType,
  type: AttachmentType,
  file: File
) => {
  return {
    id: file.name,
    type,
    metadata: {
      mime: mimeFromType[file.type],
      type: mediaType,
      url: URL.createObjectURL(file),
    },
  } as Attachment;
};

async function uploadToS3(file: File, url: string): Promise<boolean> {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });

  if (!response.ok) {
    logError(response.statusText);
    return false;
  }

  return true;
}

/**
 * Upload media files and replaces the local blob URLs with the public CDN ones.
 * @param editorState active editor state
 * @param filesForUpload files to be uploaded
 * @param getPageUploadUrl endpoint for media upload
 */
export const processEditorState = async (
  editorState: SerializedEditorState,
  filesForUpload: File[],
  getPageUploadUrl: (
    node: EditorNodes
  ) => Promise<GetPageUploadUrlMutation | null | undefined>
) => {
  if (
    editorState.root.children.length === 1 &&
    // @ts-ignore TODO: Fix types
    editorState.root.children[0].children.length === 0
  ) {
    throw new Error("Please enter some text or attach content.");
  }

  for (const key in editorState.root.children) {
    // @ts-ignore TODO: Fix types
    const node: EditorNodes = editorState.root.children[key];

    if (node?.attachmentType === PageAttachmentType.MEDIA) {
      // Handle file upload
      const file = filesForUpload.find((f) => f.name === node.attachmentName);

      if (!file) {
        // editing, already uploaded

        // https://highlightxyz.atlassian.net/browse/HIGHLIGHT-2805
        // if there is a bug somewhere and app looses list of added files
        // then they won't be uploaded
        // the alternative is to find out list of existing files by comparing with
        // previously saved content, but it just seems too complicated at the moment
        // but can be implemented if it ever becomes a pain point
        continue;
      }

      let getUrlResponse: GetPageUploadUrlMutation | undefined | null =
        undefined;
      try {
        getUrlResponse = await getPageUploadUrl(node);
      } catch (e) {
        logError({
          e,
          message: "[Editor/logic] Failed to fetch upload URL.",
        });

        throw new Error("An unexpected error occurred, please try again.");
      }

      const mediaMetadata = getUrlResponse?.getPageUploadUrl
        .metadata as PageMediaMetadata;

      const publicUrl = mediaMetadata.publicUrl;
      const url = mediaMetadata.url;

      const mediaID = getUrlResponse?.getPageUploadUrl.id;

      if (!url || !mediaID) {
        logError(
          `[Editor/logic] Failed to fetch upload URL data. Got ${getUrlResponse?.getPageUploadUrl?.metadata?.__typename}`
        );
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }

      // This can be parallelized in the future - we just need to define error handling (what to do when upload fails)
      if (!(await uploadToS3(file, url))) {
        throw new Error("Upload failed");
      }
      node.mediaUrl = publicUrl ?? "";
    }
  }

  return editorState;
};

export const parseEditorState = (editorState: string) => {
  try {
    const editorObject: SerializedEditorState = JSON.parse(editorState);

    if (editorObject.root.children.length !== 0) {
      return editorObject;
    }
  } catch (e) {
    console.log("Error parsing initial editor state.", e);
  }
};

const useEditorLogic = ({
  initialEditable,
  onFileSelected,
}: {
  initialEditable: boolean;
  onFileSelected?(f: File): void;
}) => {
  const [editor] = useLexicalComposerContext();

  const [error, setError] = useState<string>("");

  const [blockType, setBlockType] = useState("paragraph");
  const [editable, setEditable] = useState(initialEditable);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [, setSelectedElementKey] = useState<string | null>(null);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
    }
  }, [editor]);

  const clearAttachment = () => null;

  const handleFileSelected =
    (
      mediaType: SupportedMediaType,
      type: AttachmentType,
      callBack: (attachment: Attachment, fileName: string) => void
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        clearAttachment();
        return;
      }

      const file = event.target.files[0];
      event.target.value = "";

      if (mimeFromType[file.type] === undefined) {
        setError(
          `Please attach ${formatsByType[mediaType].join(
            ", "
          )} format with max size of ${sizeByType[mediaType]}`
        );
        clearAttachment();
        return;
      }

      setError("");
      const attachment = CreateAttachmentMock(mediaType, type, file);
      callBack(attachment, file.name);
      onFileSelected?.(file);
    };

  /* Effects */
  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar, initialEditable]);

  useEffect(() => {
    editor.setEditable(editable);
  }, [editor, editable]);

  /* Helpers */
  const getSelectedNode = (
    selection: RangeSelection
  ): TextNode | ElementNode => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  };

  return {
    getSelectedNode,
    activeEditor: editor,
    blockType,
    editable,
    setEditable,
    selectedElement: {
      isBold,
      isItalic,
      isLink,
    },
    handleFileSelected,
    error,
    setError,
  };
};

export type UseContentFeedEditorLogic = ReturnType<typeof useEditorLogic>;
export default useEditorLogic;

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { PrivyWagmiConnector } from "@privy-io/wagmi-connector";
import { arbitrum, arbitrumGoerli, base, baseGoerli, goerli, mainnet, optimism, optimismGoerli, polygon, polygonMumbai, zora, zoraTestnet, sepolia, mantle, } from "@wagmi/chains";
import { baseSepolia } from "viem/chains";
import { configureChains } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { envConfig } from "../../../../config";
import { forma, scroll } from "./customChains";
const chainsConfig = configureChains([
    mainnet,
    goerli,
    polygon,
    polygonMumbai,
    optimism,
    optimismGoerli,
    arbitrum,
    arbitrumGoerli,
    zora,
    zoraTestnet,
    base,
    baseGoerli,
    sepolia,
    baseSepolia,
    forma,
    mantle,
    scroll,
], [
    alchemyProvider({
        apiKey: envConfig.wagmiProviders.alchemy_api_key,
    }),
    infuraProvider({
        apiKey: envConfig.wagmiProviders.infura_api_key,
    }),
    jsonRpcProvider({
        rpc: (chain) => {
            if (chain.id === base.id && envConfig.wagmiProviders.base_rpc_url) {
                return {
                    http: envConfig.wagmiProviders.base_rpc_url,
                };
            }
            if (chain.id === baseGoerli.id &&
                envConfig.wagmiProviders.base_goerli_rpc_url) {
                return {
                    http: envConfig.wagmiProviders.base_goerli_rpc_url,
                };
            }
            return null;
        },
    }),
    alchemyProvider({
        apiKey: envConfig.wagmiProviders.alchemy_optimism_api_key,
    }),
    publicProvider(),
]);
export const CustomPrivyWagmiConnector = ({ children, }) => {
    return (_jsx(PrivyWagmiConnector, { wagmiChainsConfig: chainsConfig, children: children }));
};

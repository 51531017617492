import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { css } from "@emotion/react";
import Tooltip from "../../../../../Tooltip";
import Text from "../../../../../Typography/Text";
const attachmentStyle = {
    container: (size) => css `
      ${size === "small" &&
        css `
        padding: 10px;
      `}

      ${size === "medium" &&
        css `
        padding: 6px;
      `}

      display: inline-block;
      line-height: 0;
      cursor: pointer;
    `,
    attachments: (size) => css `
      input[type="file"] {
        display: none;
      }

      svg {
        ${size === "small" &&
        css `
            width: 12px;
            height: 12px;
          `}
        ${size === "medium" &&
        css `
            width: 16px;
            height: 16px;
          `}
      }

      border-radius: 10px;
    }
    `,
};
const BaseAttachment = ({ iconSize = "medium", title, children, }) => (_jsx("div", { css: attachmentStyle.attachments(iconSize), children: _jsx(Tooltip, { padding: "xsmall", content: _jsx(Text, { as: "span", size: "xsmall", children: title }), duration: 0, children: _jsx("div", { css: attachmentStyle.container(iconSize), children: children }) }) }));
export default memo(BaseAttachment);

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useReducer, useContext } from "react";
import { useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import { ampli } from "../../ampli";
import { STORAGE_KEYS, JWT_EXPIRATION_DAYS } from "../../config";
import { apolloClient } from "../../services/apolloClient";
import { logError } from "../../services/logger";
import { getDomain, getRootDomain, inRootDomain } from "../../utils/cookie";
import { isFullyConnected } from "./vars";
/**
 * Summary: Context Provider tracking the authenticated account
 *
 *   - Has the cached account basic details (address, id), and jwt (access token).
 *   - Is consumed at top level, prevents individual components from having to call
 *     a hook such as useSession() (from v1) to get the account details.
 *
 *   - TODO: https://highlightxyz.atlassian.net/browse/HIGHLIGHT-2721
 *           bootstrap should validate jwt is still valid?
 */
/**
 * Export Types
 */
export var AuthChoice;
(function (AuthChoice) {
    AuthChoice[AuthChoice["METAMASK"] = 0] = "METAMASK";
    AuthChoice[AuthChoice["RAINBOW"] = 1] = "RAINBOW";
})(AuthChoice || (AuthChoice = {}));
/**
 * Internal logic and state management
 */
const emptyAuth = {
    highlightAccountId: null,
    walletAddress: null,
    jwt: null,
    loading: false,
    error: null,
};
// don't export, useAuth should manage and be source of truth
const setBrowserStorageAuth = (auth) => {
    Cookies.set(STORAGE_KEYS.auth, JSON.stringify(auth), {
        expires: JWT_EXPIRATION_DAYS,
        // Root domain (highlight.xyz and its counterparts) should not propagate
        // auth cookies to subdomains (tools, mint...). Each domain should log in separately.
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies
        domain: inRootDomain() ? undefined : getDomain(),
    });
    apolloClient.cache.reset();
};
/**
 * Removes legacy domain-cascading auth cookie ".highlight.xyz" so we keep the
 * "host-only" cookie "highlight.xyz" (no leading dot).
 * This can be removed eventually. If any user after some time runs into login issues,
 * they can clean up cookies and login again.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies
 * @see https://linear.app/highlight-xyz/issue/ENG-5589/old-session-persists-even-after-connecting-other-wallet
 */
const removeLegacyAuthCookie = () => {
    Cookies.remove(STORAGE_KEYS.auth, {
        domain: getRootDomain(),
    });
};
// might need to export only for apollo client to be able to handle 401, TBD
const getBrowserStorageAuth = () => {
    if (typeof window === "undefined") {
        return null;
    }
    removeLegacyAuthCookie();
    const data = Cookies.get(STORAGE_KEYS.auth);
    return data ? JSON.parse(data) : null;
};
const removeBrowserStorageAuth = () => {
    Cookies.remove(STORAGE_KEYS.auth, {
        domain: inRootDomain() ? undefined : getDomain(),
    });
};
const getProviderName = (provider) => {
    const keys = Object.keys(provider).filter((x) => /^is[A-Z]/.test(x));
    const ignoreKeys = ["isDebug", "isConnected"];
    // there is usually `isMetamask` or `isSomething` prop
    // it's possible that there are other props, we will need to filter out those
    const key = keys
        .filter((x) => !ignoreKeys.includes(x))
        .join("-")
        .substring(2);
    return key;
};
const detectWalletType = () => {
    var _a, _b;
    if (!window.ethereum) {
        return "[none]";
    }
    const name = getProviderName(window.ethereum);
    if (name) {
        return name;
    }
    if ((_a = window.ethereum) === null || _a === void 0 ? void 0 : _a.providers) {
        return (_b = window.ethereum) === null || _b === void 0 ? void 0 : _b.providers.map((p) => getProviderName(p)).sort().join("|");
    }
    return "[error]";
};
const trackWalletConnect = (payload) => {
    if (!ampli.isLoaded) {
        return;
    }
    const { walletAddress } = payload;
    // payload.walletAddress
    ampli.connectWallet({
        walletAddress,
        walletType: detectWalletType(),
        pagePath: window.location.pathname,
    });
};
const initialState = getBrowserStorageAuth() || emptyAuth;
/**
 * PRIVATE - top level Actions that other component can calls (these actions know how to safely manage state)
 */
const resetSession = () => {
    // TODO - somehow check if currently logged in, would need access to current state,
    // reset any session data / switch users?
    try {
        apolloClient.resetStore();
        // segmentTrackEvent(ANALYTICS_EVENT.ACCOUNT_SIGN_OUT);
    }
    catch (error) {
        logError({ error, message: "auth-context: logout error" });
    }
};
const logout = async (dispatch) => {
    try {
        dispatch({ type: "LOGOUT" });
        resetSession();
    }
    catch (error) {
        logError({ error, message: "useSession/logout error" });
    }
};
const login = async (payload, dispatch) => {
    try {
        dispatch({
            type: "LOGIN_SUCCESS",
            payload,
        });
        trackWalletConnect(payload);
    }
    catch (e) {
        dispatch({ type: "LOGIN_ERROR", payload: { error: e } });
        logError({ e, message: "useSession/logIntoAccount error" });
        await resetSession();
        throw e;
    }
};
/*
  // TODO: need to be smart about where we put the chain change event listener boot
  // TODO: should context independent side-effects be handled elsewhere?
 */
function reducer(state, action) {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return { ...initialState, loading: true };
        case "LOGOUT":
            removeBrowserStorageAuth();
            return { ...emptyAuth };
        case "LOGIN_SUCCESS":
            setBrowserStorageAuth({ ...action.payload, loading: false, error: null });
            return { ...action.payload, loading: false, error: null };
        case "LOGIN_ERROR":
            return { ...initialState, ...action.payload };
        default:
            throw new Error("unknown reducer-action type");
    }
}
/**
 * Exported context and provider hooks
 */
const ctx = createContext({
    state: initialState,
    dispatch: () => null,
});
function ContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    return _jsx(ctx.Provider, { value: { state, dispatch }, ...props });
}
function useAuth() {
    const context = useContext(ctx);
    if (context === undefined)
        throw new Error(`No provider for AuthContext given`);
    return {
        ...context,
        // using apollo var to ensure that all instances embeds are notified about new value
        isFullyConnected: useReactiveVar(isFullyConnected),
    };
}
export { ContextProvider, useAuth, logout, login };

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND } from "lexical";
import { Italic as ItalicIcon } from "../../../../../../assets/icons.generated";
import { IS_APPLE } from "../../../../../../config";
import { textStylesButton } from "../styles";
const ItalicToggle = ({ selectedElement, dark = false, }) => {
    const [activeEditor] = useLexicalComposerContext();
    const formatItalicSelection = () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
    };
    return (_jsx("button", { css: textStylesButton(dark, selectedElement.isItalic), onClick: formatItalicSelection, title: IS_APPLE ? "Italic (⌘I)" : "Italic (Ctrl+I)", "aria-label": `Format text as italics. Shortcut: ${IS_APPLE ? "⌘I" : "Ctrl+I"}`, children: _jsx(ItalicIcon, {}) }));
};
export default memo(ItalicToggle);

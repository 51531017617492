import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
    marginRight: "-4px",
  },
  body: {
    maxHeight: "80vh",
    overflowX: "hidden",
    overflowY: "auto",
    padding: `0 ${theme.spacing.md}px`,
    margin: `${theme.spacing.md}px -${theme.spacing.md}px 0 -${theme.spacing.md}px`,
  },

  closeIconContainer: {
    padding: "8px",
    lineHeight: 0,
  },
  base: {
    position: "relative",
    background: theme.colors.primaryBackground[0],
    borderRadius: theme.radius.sm,
    maxWidth: "700px",
    outline: "none",
    boxShadow: theme.shadows.lg,
    maxHeight: "90%",
    padding: `${theme.spacing.sm}px ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.md}px`,
    transition: "all 0.8s cubic-bezier(0.25, 1, 0.5, 1)",
  },
  afterOpen: {
    transitionDelay: "0.1s",
    opacity: 1,
    transform: "translateY(0)",
  },
  beforeCLose: {
    opacity: 0,
    transform: "translateY(30px)",
  },
  overlayBase: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.8s cubic-bezier(0.25, 1, 0.5, 1)",
    zIndex: 200,

    "> div": {
      width: "100%",
    },
  },
  overlayAfterOpen: {
    background: theme.colors.overlay[0],
  },
  overlayBeforeClose: {
    background: theme.colors.primaryBackground[0],
  },
}));

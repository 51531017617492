import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { borderStyle, containerStyles } from "./styles";
const SystemCardContainer = ({ backgroundShade, background = "primary", as = "div", border = false, borderRadius = "medium", borderColor, borderWidth, padding = "medium", flushHorizontal = false, shadow, children, extraCss, additionalCss, onClick, ...rest }) => {
    const Tag = as;
    return (_jsx(Tag, { onClick: onClick, css: [
            containerStyles(background, backgroundShade, padding, flushHorizontal, shadow),
            borderStyle(border, borderRadius, borderColor, borderWidth),
            additionalCss,
            extraCss,
        ], ...rest, children: children }));
};
export default memo(SystemCardContainer);

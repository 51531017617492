import { useEffect, useMemo } from "react";

import { ThemeProvider } from "@emotion/react";
import { LoadingPage } from "@hl/base-components/lib/LoadingPage";
import { maskAddress } from "@hl/communities-app/lib/utils/content";
import { useAuthChangedCallback } from "@hl/shared-features/lib/features/auth/hooks";
import { NotFound, ServerError } from "@hl/shared-features/lib/features/layout";
import { Stack } from "@mantine/core";
import { useParams } from "react-router-dom";

import { EventType, Page } from "apollo/graphql.generated";
import { AppSEO } from "~/features/layout/Layout/SEO";

import FeatureNotAvailable from "../..//layout/FeatureNotAvailable";
import PageText from "../../../components/Page/Text";
import useAnalytics from "../../../hooks/useAnalytics";
import { defaultPageTheme } from "../../../styles/theme/theme";
import { createThemeForPage } from "../../../styles/theme/themeForPage";
import { useAuth } from "../../auth/context";
import Layout from "../../layout/Layout";
import { LockedPage } from "../LockedPage";
import PageSectionView from "../PageSectionView";
import {
  PageViewDocument,
  usePageViewQuery,
} from "../queries/page.graphql.generated";

import * as styles from "./styles";

type UnlockedPageProps = {
  page: Omit<Page, "name">;
};
export const UnlockedPage: React.FC<UnlockedPageProps> = ({ page }) => {
  const mainSection = page.sections.find((section) => section.id === "main");
  return (
    <div>
      {mainSection && (
        <PageSectionView
          pageSection={mainSection}
          vectorId={mainSection.content.mintContent?.id ?? ""}
        />
      )}
    </div>
  );
};

export const usePage = ({ pageSlug }: { pageSlug?: string }) => {
  const { data, error, loading, refetch } = usePageViewQuery({
    variables: {
      slug: pageSlug ?? "",
    },
  });
  const { walletAddress, highlightAccountId } = useAuth();
  const { trackEvent } = useAnalytics();

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const pageFailure = (errorData: Record<string, any>) => {
    trackEvent(EventType.ViewGatedPage_Locked, errorData);
  };

  useAuthChangedCallback(() => refetch());

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions.status == 404) {
      pageFailure({ isError: true, found: false, error: "404 - Not Found" });
      return;
    }

    if (data?.pageView === null) {
      pageFailure({ isError: true, found: true, error: "404 - Not Found" });
      return;
    }

    if (!loading) {
      if (error || !data) {
        pageFailure({ isError: true, found: true, error: "Unknown Error" });
        return;
      }

      if (data.pageView.isLocked) {
        trackEvent(EventType.ViewGatedPage_Locked, {
          walletAddress,
          highlightAccountId,
          pageSlug,
        });
      } else {
        trackEvent(EventType.ViewGatedPage_Unlocked, {
          walletAddress,
          highlightAccountId,
          pageSlug,
        });
      }
    }
  }, [error, data]);

  const theme = data?.pageView?.theme ?? defaultPageTheme;

  const pageTheme = useMemo(() => createThemeForPage(theme), [theme]);

  return {
    loading,
    pageTheme,
    error,
    data,
  };
};

export const PageView = () => {
  const { pageSlug } = useParams();
  const { loading, pageTheme, error, data } = usePage({ pageSlug });

  if (loading) {
    return (
      <Layout showHeader={false}>
        <LoadingPage marginBottom={100} />
      </Layout>
    );
  }

  if (error?.graphQLErrors[0]?.extensions.status == 404) {
    return <NotFound />;
  }

  if (data?.pageView === null) {
    return <FeatureNotAvailable />;
  }
  const gate = data?.pageView.gate;

  if (error || !data) {
    return <ServerError />;
  }

  if (data.pageView.isLocked && !gate) {
    return <div>Page data is corrupted</div>;
  }

  const creator = data?.pageView.creator;
  const creatorName = creator
    ? creator.ensName ??
      maskAddress(
        creator.evmAddresses?.length ? creator.evmAddresses[0] : "",
        6,
        4
      )
    : "";

  const creatorDescription = creatorName ? (
    <Stack spacing={0}>
      <PageText as="span" extraCss={styles.createdBy}>
        Created by
      </PageText>
      <PageText as="span" extraCss={styles.creator}>
        {" "}
        {creatorName}
      </PageText>
    </Stack>
  ) : (
    <></>
  );
  const pageDescription = creator ? `Page by ${creatorName}` : undefined;

  return (
    <ThemeProvider theme={pageTheme}>
      <AppSEO title={pageDescription} />

      <Layout
        extraCss={styles.layoutContainer}
        navLayout="page"
        header={creatorDescription}
      >
        {data.pageView.isLocked ? (
          <LockedPage
            creatorAddress={data.pageView.creator?.evmAddresses[0]}
            creatorEns={data.pageView.creator?.ensName}
            lockedMessage={gate?.lockedMessage}
            unlockGateResult={data?.pageView?.unlockGateResult ?? undefined}
            refetchOnRequirementChange={[PageViewDocument]}
          />
        ) : (
          <UnlockedPage page={data.pageView.page!} />
        )}
      </Layout>
    </ThemeProvider>
  );
};

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection, } from "lexical";
import { ArrowDownBoldSmall as ArrowIcon } from "../../../../../../assets/icons.generated";
import CardContainer from "../../../../../CardContainer";
import Text from "../../../../../Typography/Text";
import useOnClickOutside from "../../../../../../utils/hooks/useOnClickOutside";
const styleHeadingSelector = {
    container: css `
    position: relative;
  `,
    selectorButton: () => css `
      background: inherit;
      border-radius: 10px;
      padding: 0 10px 0 12px;
      height: 32px;
      min-width: 142px;

      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      svg {
        margin-left: 6px;
      }
    `,
    dropdown: css `
    position: absolute;
    top: calc(32px + 3px);
    left: -5px;
    z-index: 1;
    padding: 6px;
  `,
    dropdownButton: (active) => (theme) => css `
      white-space: nowrap;
      padding: 0 10px;
      height: 32px;
      border-radius: 5px;
      width: 100%;
      text-align: left;

      ${active &&
        css `
        background: ${theme.background.colors.secondary};

        span {
          font-weight: 600;
        }
      `};

      @media (hover: hover) {
        &:hover {
          background: ${theme.background.colors.secondary};
        }
      }
    `,
};
const HeadingSelector = ({ blockType, dark = false }) => {
    const ref = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [activeEditor] = useLexicalComposerContext();
    const formatHeading = (headingSize) => {
        if (blockType !== headingSize) {
            activeEditor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode(headingSize));
                    setShowDropdown(false);
                }
            });
        }
    };
    const formatParagraph = () => {
        if (blockType !== "paragraph") {
            activeEditor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode());
                    setShowDropdown(false);
                }
            });
        }
    };
    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };
    useOnClickOutside(ref, () => setShowDropdown(false));
    const HeadingButtonLabel = () => {
        let label = "Paragraph";
        switch (blockType) {
            case "h1":
                label = "Large Heading";
                break;
            case "h2":
                label = "Medium Heading";
                break;
            case "h3":
                label = "Small Heading";
                break;
        }
        return _jsx(_Fragment, { children: label });
    };
    const HeadingTagsDropdown = () => {
        if (!showDropdown)
            return null;
        return (_jsxs(CardContainer, { shadow: "md", extraCss: styleHeadingSelector.dropdown, children: [_jsx("button", { css: styleHeadingSelector.dropdownButton(blockType === "h1"), onClick: () => formatHeading("h1"), children: _jsx(Text, { as: "span", size: "small", children: "Large Heading" }) }), _jsx("button", { css: styleHeadingSelector.dropdownButton(blockType === "h2"), onClick: () => formatHeading("h2"), children: _jsx(Text, { as: "span", size: "small", children: "Medium Heading" }) }), _jsx("button", { css: styleHeadingSelector.dropdownButton(blockType === "h3"), onClick: () => formatHeading("h3"), children: _jsx(Text, { as: "span", size: "small", children: "Small Heading" }) }), _jsx("button", { css: styleHeadingSelector.dropdownButton(blockType === "paragraph"), onClick: formatParagraph, children: _jsx(Text, { as: "span", size: "small", children: "Paragraph" }) })] }));
    };
    return (_jsxs("div", { css: styleHeadingSelector.container, ref: ref, children: [_jsxs("button", { css: styleHeadingSelector.selectorButton(), onClick: handleDropdownToggle, children: [_jsx(Text, { as: "span", size: "small", bold: true, inverted: dark, children: _jsx(HeadingButtonLabel, {}) }), _jsx(ArrowIcon, {})] }), _jsx(HeadingTagsDropdown, {})] }));
};
export default memo(HeadingSelector);

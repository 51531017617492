import { css } from "@emotion/react";
import { bottomMargin } from "../../../../../styles/global/layout";
export const container = (theme) => css `
  ${bottomMargin("7/20px")}
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;

  position: sticky;
  top: 74px;
  z-index: 2;

  &:after {
    content: " ";
    width: calc(100% + 40px);
    height: 56px;
    position: absolute;
    top: -10px;
    left: -20px;
    z-index: -1;
    border-radius: 10px;
    background-color: #2b2b2b;
  }

  ${theme.mediaQueries.mediumDown} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-content: flex-start;

    &:after {
      height: 100px;
      left: -16px;
      width: calc(100% + 16px * 2);
    }
  }
`;
export const typography = css `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;
export const actions = (theme) => css `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  ${theme.mediaQueries.mediumDown} {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
export const textStylesButton = (dark, selected, opaque) => (theme) => css `
      transition-property: background, opacity;
      transition-duration: 0.4s;
      transition-timing-function: ${theme.animations.easeOutQuart};

      padding: 6px;
      border-radius: 10px;
      line-height: 0;

      ${selected &&
    css `
        background: ${dark
        ? theme.background.colors.quaternary
        : theme.background.colors.secondary};
        color: ${theme.text.colors.primary};
        opacity: 1;
      `};
      ${!selected &&
    opaque &&
    css `
        opacity: 0.4;
      `};
    `;
export const actionButton = (selected) => (theme) => css `
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    &:not(:first-of-type) {
      margin-left: 20px;
    }

    span {
      transition: background 0.6s ${theme.animations.easeOutQuart};

      margin-right: 8px;
      background: transparent;
      width: 20px;
      height: 20px;
      border: 1px solid ${theme.colorMap.utility.divider};
      border-radius: 4px;

      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      ${selected &&
    css `
        background: black;
      `}
    }
  `;

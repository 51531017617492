import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import get from "lodash/get";
const styles = {
    container: (theme) => css `
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px solid ${get(theme, "page.text.color", "black")};
    background-color: ${theme.colorMap.utility.divider};
  `,
};
export const PublishToPreview = () => (_jsx("div", { css: styles.container, children: "Embedded content (shown on preview)" }));

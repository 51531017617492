import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Image as ImageIcon } from "../../../../../../assets/icons.generated";
import { AttachmentType, SupportedMediaType, } from "../../../types";
import { INSERT_IMAGE_COMMAND } from "../../ImageAttachmentPlugin";
import BaseFileAttachment from "../BaseFileAttachment";
const ImageAttachment = ({ handleFileSelected }) => {
    const [activeEditor] = useLexicalComposerContext();
    const insertImage = (attachment, fileName) => {
        activeEditor.focus();
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
            attachment,
            fileName,
        });
    };
    return (_jsx(BaseFileAttachment, { title: "Image", id: "LexicalEditorImageFileUpload", type: "file", accept: "image/*", onChange: handleFileSelected(SupportedMediaType.IMAGE, AttachmentType.MEDIA, insertImage), children: _jsx(ImageIcon, {}) }));
};
export default memo(ImageAttachment);

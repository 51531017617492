import { css } from "@emotion/react";
import type { EditorThemeClasses } from "lexical";

// TODO: Move other styles to Lexical theme.
// https://lexical.dev/docs/getting-started/theming
export const editorThemeClasses: EditorThemeClasses = {
  list: {
    nested: {
      listitem: "GatesEditorTheme__nestedListItem",
    },
    ul: "GatesEditorTheme__ul",
  },
};

export const editorTheme = css`
  .GatesEditorTheme__nestedListItem {
    list-style-type: none;
  }

  .GatesEditorTheme__ul {
    list-style-position: inside;
  }
`;

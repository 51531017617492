import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import { MimeTypes } from "../../../config";
/**
 * Styles
 */
const styles = {
    nodeWrapper: css `
    position: relative;
  `,
    video: css `
    max-width: 100%;
  `,
};
/**
 * Helpers
 */
const isMediaMetadata = (toBeDefined) => {
    if (!toBeDefined)
        return false;
    return toBeDefined.mime !== undefined;
};
const VideoAttachment = ({ attachment }) => {
    if (!isMediaMetadata(attachment) || !attachment.url) {
        return null;
    }
    return (_jsxs("video", { controls: true, controlsList: "nodownload", css: styles.video, children: [_jsx("source", { src: attachment === null || attachment === void 0 ? void 0 : attachment.url, type: MimeTypes[attachment.mime] }), "Your browser does not support video."] }));
};
export default VideoAttachment;

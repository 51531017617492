import { memo, useEffect } from "react";

import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { css } from "@emotion/react";
import TextArea from "@hl/communities-app/lib/components/Inputs/TextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mantine/core";
import { NodeKey } from "lexical";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";

import ModalBase from "~/features/layout/Modal/ModalBase";

import { Modal, ModalType, modalVar } from "../../../layout/Modal/modal";

const styles = {
  usernameInput: css`
    margin-bottom: 16px;
  `,
  title: css`
    text-align: center;
    margin: 0 0 32px 0;
  `,
};

export type ModalPostEditorHTMLTagSelectData = {
  insertHTMLTag?: (html: string) => void;
  editHTMLTag?: (html: string, nodeKey: NodeKey) => void;
  html?: string;
  nodeKey?: NodeKey;
};

export type ModalPostEditorHTMLTagSelect = ReactiveVar<
  Modal<ModalPostEditorHTMLTagSelectData>
>;

export type HTMLSubmitForm = {
  html: string;
};

const HTMLTagSelectModal = () => {
  const { showModal, data } = useReactiveVar(
    modalVar as ModalPostEditorHTMLTagSelect
  );

  const validationSchema = yupObject({
    html: yupString().required("Please enter HTML."),
  }).required();

  const onSubmit = async (formData: HTMLSubmitForm) => {
    if (data.insertHTMLTag) {
      data.insertHTMLTag(formData.html);
    }

    if (data.editHTMLTag && data.nodeKey) {
      data.editHTMLTag(formData.html, data.nodeKey);
    }
  };

  const cleanup = () => {
    resetField("html");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
  } = useForm<HTMLSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (data?.html) {
      setValue("html", data.html);
    }
  }, [data?.html]);

  return (
    <ModalBase
      title="HTML tag"
      isOpen={showModal === ModalType.EDITOR_HTML_SELECT}
      onAfterClose={cleanup}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextArea
            additionalCss={styles.usernameInput}
            label="HTML to embed"
            placeholder="<div>Your HTML embed here </div>"
            {...register("html")}
            error={errors.html?.message}
          />
          <Button fullWidth size="xl" type="submit">
            Add
          </Button>
        </form>
      </div>
    </ModalBase>
  );
};

export default memo(HTMLTagSelectModal);

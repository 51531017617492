import { useEffect, useState } from "react";

import { SerializedEditorState } from "lexical";

import { PageSection, SectionType } from "apollo/graphql.generated";

import Editor from "../../dashboard/Editor";
import { parseEditorState } from "../../dashboard/Editor/logic";

import * as styles from "./styles";

interface PageSectionViewProps {
  pageSection: PageSection;
  showEmbedNodes?: boolean;
  vectorId?: string;
}

interface PageSectionProps {
  pageSection: PageSection;
  showEmbedNodes?: boolean;
  vectorId?: string;
}

const EditorSection = ({ pageSection }: PageSectionProps) => {
  const [editorState, setEditorState] = useState<
    SerializedEditorState | undefined
  >();
  useEffect(() => {
    if (pageSection?.content) {
      if (!pageSection.content.editorContent!.state) {
        setEditorState(undefined);
      } else {
        setEditorState(
          parseEditorState(pageSection.content.editorContent!.state)
        );
      }
    }
  }, [pageSection]);

  return <Editor readOnlyState={editorState} />;
};

const MintButtonSection = () => {
  return null;
};

/**
 * Mapping of Section types and their components.
 */
const sectionComponentMap: {
  [key in SectionType]: React.FC<PageSectionProps>;
} = {
  EditorData: EditorSection,
  MintButton: MintButtonSection,
};

const PageSectionView = ({ pageSection, vectorId }: PageSectionViewProps) => {
  const Component = pageSection.type && sectionComponentMap[pageSection.type];

  return (
    <div css={styles.pageSection}>
      <Component pageSection={pageSection} vectorId={vectorId} />
    </div>
  );
};

export default PageSectionView;

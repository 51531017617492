import { css, Theme } from "@emotion/react";

import { CornersShape } from "apollo/graphql.generated";

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
  height: 100%;

  svg {
    display: inline;
  }
`;

export const lockIcon = css`
  fill: #000;
`;

export const card = (theme: Theme, props: { isPreview?: boolean }) => css`
  background-color: #fff;
  padding: 20px;
  margin: 20px;

  width: auto;
  text-align: center;

  ${theme.mediaQueries.mediumUp} {
    width: ${props.isPreview ? "auto" : "50%"};
  }

  border-radius: ${theme.page.corners.shape === CornersShape.Rounded
    ? "10px"
    : "0"};

  box-shadow: 0px 4px 40px -4px rgba(0, 0, 0, 0.2);
`;

export const connectButtonPreview = css`
  width: 100%;
  background: #000000;
  border: 1px solid #000;
  margin-top: 10px;
  padding: 10px 0px;
  }
`;

export const connectButton = (theme: Theme) => css`
  width: 100%;
  margin-top: 10px;
  padding: 0;

  > div * {
    height: auto !important;
    line-height: ${theme.text.lineHeights.medium} !important;
    font-size: ${theme.text.sizes.medium} !important;
    padding: 10px 0px !important;
    background: #000000 !important;
    color: white !important;
    text-align: center !important;
    border: 1px solid #000 !important;
  }
`;

export const centeredButton = css`
  button {
    width: 100%;
    justify-content: center;
  }
`;

export const buyButton = css`
  border: 1px solid #000;
  margin-top: 16px;
  padding: 10px 0px;
`;

export const lockedMessage = css`
  padding: 0 12px;
  white-space: pre-wrap;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

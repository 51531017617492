import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import "emoji-mart/css/emoji-mart.css";
import { memo, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Picker } from "emoji-mart";
import { FaceSmile as AddReactionIcon } from "../../../../../../assets/icons.generated";
import useOnClickOutside from "../../../../../../utils/hooks/useOnClickOutside";
import { INSERT_EMOJI_COMMAND } from "../../EmojiPickerPlugin";
import { textStylesButton } from "../styles";
const getPickerCss = (up = false, left = false) => css `
    position: absolute;
    ${up ? "bottom" : "top"}: 40px;
    ${left ? "right" : "left"}: 40px;
    z-index: 1;
  `;
const EmojiPicker = ({ dark = false }) => {
    const [activeEditor] = useLexicalComposerContext();
    const insertEmoji = (data) => {
        activeEditor.dispatchCommand(INSERT_EMOJI_COMMAND, data.native);
    };
    const ref = useRef(null);
    const [pickerCss, setPickerCss] = useState(null);
    const closePicker = () => {
        setPickerCss(null);
    };
    useOnClickOutside(ref, closePicker);
    const toggleReactionPicker = (event) => {
        if (pickerCss) {
            closePicker();
            return;
        }
        if (visualViewport) {
            const onSecondHalfY = event.clientY > visualViewport.height / 2;
            const onSecondHalfX = event.clientX > visualViewport.width / 2;
            setPickerCss(getPickerCss(onSecondHalfY, onSecondHalfX));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("button", { css: textStylesButton(dark, false), onClick: toggleReactionPicker, children: _jsx(AddReactionIcon, {}) }), pickerCss && (_jsx("div", { ref: ref, css: pickerCss, children: _jsx(Picker, { showPreview: false, showSkinTones: false, title: "", emoji: "", onClick: (data) => insertEmoji(data) }) }))] }));
};
export default memo(EmojiPicker);

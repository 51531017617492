import { css, Theme } from "@emotion/react";

export const layoutContainer = (theme: Theme) => css`
  max-width: 600px;
  padding-top: ${theme.verticalSpaces.large[4]};

  ${theme.mediaQueries.smallOnly} {
    padding-top: 0;
  }
`;

export const createdBy = (theme: Theme) => css`
  font-weight: ${theme.text.fontWeights.normal};
  opacity: 0.6;
`;

export const creator = css`
  font-weight: 600;
`;

import { css, Theme } from "@emotion/react";

export const container = css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 20px 20px 20px;
  margin: 0;
`;

export const logo = (usePageTheme: boolean) => (theme: Theme) =>
  css`
    height: 12px;
    path {
      fill: ${getLogoColor(usePageTheme, theme)};
    }
  `;

const getLogoColor = (usePageTheme: boolean, theme: Theme) => {
  if (!usePageTheme) return "black";
  return theme.page.text.color;
};

import { ChangeEvent, memo } from "react";

import AudioAttachment from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/AudioAttachment";
import EmbedAttachment from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/EmbedAttachment";
import HTMLAttachment, {
  InsertHTMLTagHandler,
} from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/HTMLAttachment";
import ImageAttachment from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/ImageAttachment";
import VideoAttachment from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/VideoAttachment";
import BoldToggle from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/BoldToggle";
import BulletListToggle from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/BulletListToggle";
import EmojiPicker from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/EmojiPicker";
import HeadingSelector from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/HeadingSelector";
import ItalicToggle from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/ItalicToggle";
import LinkInsert from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Toolbar/LinkInsert";
import {
  SelectedElement,
  Attachment,
  AttachmentType,
  SupportedMediaType,
} from "@hl/communities-app/lib/components/ContentFeed/TextEditor/types";

import {
  ModalType,
  updateModalVar,
  resetModalVar,
} from "../../../../layout/Modal/modal";
import { ModalPostEditorEmbedTagSelectData } from "../../components/EmbedTagSelectModal";
import { ModalPostEditorHTMLTagSelectData } from "../../components/HTMLTagSelectModal";

import * as styles from "./styles";

interface ToolbarPluginProps {
  selectedElement: SelectedElement;
  blockType: string;
  isEditable: boolean;
  //TODO: Use type from Editor types file
  handleFileSelected: (
    mediaType: SupportedMediaType,
    type: AttachmentType,
    callBack: (attachment: Attachment, fileName: string) => void
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  anchorElem?: HTMLElement;
  disableHTMLPlugin: boolean;
}

const ToolbarPlugin = ({
  selectedElement,
  blockType,
  isEditable,
  handleFileSelected,
  anchorElem,
  disableHTMLPlugin,
}: ToolbarPluginProps) => {
  if (!isEditable) return null;

  const showEmbedTagSelectorModal = () => {
    updateModalVar<ModalPostEditorEmbedTagSelectData>({
      showModal: ModalType.EDITOR_EMBED_SELECT,
    });
  };

  const showHTMLTagSelectorModal = (insertHandler: InsertHTMLTagHandler) => {
    updateModalVar<ModalPostEditorHTMLTagSelectData>({
      showModal: ModalType.EDITOR_HTML_SELECT,
      data: {
        insertHTMLTag: insertHandler,
      },
    });
  };

  return (
    <div css={styles.toolbar}>
      <div css={styles.leftBar}>
        <div css={styles.headingSelector}>
          <HeadingSelector blockType={blockType} />
        </div>
        <div css={styles.pluginGroup}>
          <BoldToggle selectedElement={selectedElement} />
          <ItalicToggle selectedElement={selectedElement} />
          <BulletListToggle blockType={blockType} />
        </div>
        <EmojiPicker />
      </div>
      <div css={styles.rightBar}>
        <LinkInsert selectedElement={selectedElement} anchorElem={anchorElem} />
        <ImageAttachment handleFileSelected={handleFileSelected} />
        <AudioAttachment handleFileSelected={handleFileSelected} />
        <VideoAttachment handleFileSelected={handleFileSelected} />
        <EmbedAttachment
          onEmbedButtonClickHandler={showEmbedTagSelectorModal}
          postInsertHandler={resetModalVar}
        />
        {!disableHTMLPlugin && (
          <HTMLAttachment
            onHTMLButtonClickHandler={showHTMLTagSelectorModal}
            postInsertHandler={resetModalVar}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ToolbarPlugin);

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useRef, useState, useCallback, useEffect } from "react";
import { css } from "@emotion/react";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { $setSelection, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND, } from "lexical";
import Button from "../../../Button";
import CardContainer from "../../../CardContainer";
import { getSelectedNode, setFloatingElemPosition, } from "../utils";
/**
 * Styles
 */
const styles = {
    linkEditor: css `
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -5px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
  `,
    cardContainer: (theme) => css `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 0.5px solid ${theme.colorMap.utility.divider};
  `,
    linkInput: (theme) => css `
    display: block;
    border-radius: 15px;
    background-color: ${theme.background.colors.secondary};
    color: ${theme.text.colors.primary};
    border: 0;
    outline: 0;
    appearance: none;
    position: relative;
    padding: 8px 15px;
    font-family: ${theme.text.fontFamily.primary};
    font-size: ${theme.text.sizes.small};
    width: 100%;
    line-height: 1.3;
    letter-spacing: 0;
    font-weight: bold;
    width: 100%;

    :not(:focus) {
      color: ${theme.text.colors.primary};
    }

    ::placeholder {
      color: initial;
    }

    color: unset a {
      color: ${theme.colorMap.accent.primary};
      text-decoration: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 25px;
      text-overflow: ellipsis;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;

      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
    linkEdit: css `
    position: absolute;
    top: 7px;
    right: 13px;
    cursor: pointer;
  `,
    applyButton: (theme) => css `
    border: none;
    width: 100px;
    color: ${theme.colorMap.accent.primary};
  `,
};
// TODO: If this is considered a good replacement for link editing, migrate this component to Communities and test in Content Feed.
function SimpleFloatingLinkEditor({ editor, anchorElem = document.body, }) {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const [linkUrl, setLinkUrl] = useState("");
    const [lastSelection, setLastSelection] = useState(null);
    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            }
            else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            }
            else {
                setLinkUrl("");
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;
        if (editorElem === null) {
            return;
        }
        const rootElement = editor.getRootElement();
        if (selection !== null &&
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            }
            else {
                rect = domRange.getBoundingClientRect();
            }
            setFloatingElemPosition(rect, editorElem, anchorElem);
            setLastSelection(selection);
        }
        else if (!activeElement || activeElement.className !== "link-input") {
            if (rootElement !== null) {
                setFloatingElemPosition(null, editorElem, anchorElem);
            }
            setLastSelection(null);
            setLinkUrl("");
        }
        return true;
    }, [anchorElem, editor]);
    useEffect(() => {
        return mergeRegister(editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                updateLinkEditor();
            });
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            updateLinkEditor();
            return true;
        }, COMMAND_PRIORITY_LOW));
    }, [editor, updateLinkEditor]);
    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);
    const handleApply = () => {
        if (lastSelection !== null) {
            if (linkUrl !== "") {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                editor.update(() => {
                    $setSelection(null);
                });
            }
        }
    };
    return (_jsx("div", { ref: editorRef, className: "link-editor", css: styles.linkEditor, children: _jsxs(CardContainer, { shadow: "xl", extraCss: styles.cardContainer, children: [_jsx("input", { ref: inputRef, className: "link-input", css: styles.linkInput, value: linkUrl, onChange: (event) => {
                        setLinkUrl(event.target.value);
                    }, placeholder: "https://", onKeyDown: (event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            handleApply();
                        }
                        else if (event.key === "Escape") {
                            event.preventDefault();
                        }
                    } }), _jsx(Button, { onClick: handleApply, size: "small", color: "secondary", extraCss: styles.applyButton, children: "Apply" })] }) }));
}
export default SimpleFloatingLinkEditor;

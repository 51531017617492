import { makeVar, ReactiveVar } from "@apollo/client";

export enum ModalType {
  PUBLISH_PAGE = "publish-page",
  DELETE_PAGE = "delete-page",
  EDITOR_EMBED_SELECT = "editor-embed-select",
  EDITOR_HTML_SELECT = "editor-html-select",
  EDIT_PAGE_NAME = "edit-page-name",
  PUBLISH_PAGE_DISABLED = "publish-page-disabled",
  IN_PROGRESS_EVM_TRANSACTIONS = "in-progress-evm-tx",
  TEST_DATE = "test-date",
  CREATE_EDITION = "create-edition",
  EDIT_SALE = "edit-sale",
  SALE_AVAILABILITY = "sale-availability",
  SHARE_SALE = "share-sale",
  EMBED_GATE_PAGE = "embed-gate-page",
  GATED_ENTITY_ALL_PREREQS = "gated-entity-all-prereqs",
}

export type Modal<T> = {
  showModal: ModalType | null;
  showView: string | null;
  data: T;
  onClose?: () => void;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const emptyModal: Modal<any> = {
  showModal: null,
  showView: null,
  data: null,
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const modalVar = makeVar<Modal<any>>(emptyModal);

export const resetModalVar = () => {
  modalVar(emptyModal);
};

export const setModalVar = <T>(data: Partial<Modal<T>>) => {
  modalVar({ ...emptyModal, ...data });
};

export const updateModalVar = <T>(data: Partial<Modal<T>>) => {
  if (modalVar().showModal != data.showModal) {
    modalVar({ ...modalVar(), ...data });
  }
};

/**
 * Modal data types
 */
export type ModalWithoutTypeData = Record<string, string | boolean | string[]>;
export type ModalWithoutType = ReactiveVar<Modal<ModalWithoutTypeData>>;

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { MusicNote as AudioIcon } from "../../../../../../assets/icons.generated";
import { AttachmentType, SupportedMediaType, } from "../../../types";
import { INSERT_AUDIO_COMMAND } from "../../AudioAttachmentPlugin";
import BaseFileAttachment from "../BaseFileAttachment";
const AudioAttachment = ({ handleFileSelected }) => {
    const [activeEditor] = useLexicalComposerContext();
    const insertAudio = (attachment, fileName) => {
        activeEditor.focus();
        activeEditor.dispatchCommand(INSERT_AUDIO_COMMAND, {
            attachment,
            fileName,
        });
    };
    return (_jsx(BaseFileAttachment, { title: "Audio", id: "LexicalEditorAudioFileUpload", type: "file", accept: "audio/*", onChange: handleFileSelected(SupportedMediaType.AUDIO, AttachmentType.MEDIA, insertAudio), children: _jsx(AudioIcon, {}) }));
};
export default memo(AudioAttachment);

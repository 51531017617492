import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createTextNode, $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, createCommand, } from "lexical";
export const INSERT_EMOJI_COMMAND = createCommand();
export default function EmojiPickerPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(INSERT_EMOJI_COMMAND, (payload) => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                selection.insertNodes([$createTextNode(payload)]);
            }
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { layoutContext } from "../../apollo/reactive/layout";
import { LoadingIcon as LoadingIconSVG } from "../../assets/icons.generated";
const applyColor = (inheritColor, communityTheme) => (theme) => css `
      path {
        stroke: ${inheritColor
    ? "currentColor"
    : communityTheme === "system"
        ? theme.text.colors.primary
        : theme.community.text["onPageBackgroundPrimaryColor"]};
      }
    `;
const MotionLoadingIcon = motion(LoadingIconSVG, { forwardMotionProps: true });
const LoadingIcon = ({ inheritColor = false, overwriteTheme, className, }) => {
    const layout = layoutContext();
    const theme = overwriteTheme !== null && overwriteTheme !== void 0 ? overwriteTheme : layout.theme;
    return (_jsx(MotionLoadingIcon, { className: className, css: applyColor(inheritColor, theme !== null && theme !== void 0 ? theme : "system"), animate: { rotate: 360 }, transition: { repeat: Infinity, duration: 1, ease: "linear" } }));
};
export default memo(LoadingIcon);

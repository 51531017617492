import { css } from "@emotion/react";
import { borderTypes } from "../../styles/theme/theme";
const getColorTypeMap = (theme) => ({
    error: theme.colorMap.destructive,
    info: theme.colorMap.accent,
});
export const container = (type, margin, small) => (theme) => css `
      display: flex;
      ${margin && "margin: 1rem 0;"}
      padding: 20px;
      background: ${getColorTypeMap(theme)[type][50]};
      border: 2px solid ${getColorTypeMap(theme)[type][500]};
      gap: ${small ? "0.5rem" : "0.75rem"};
      p {
        color: ${getColorTypeMap(theme)[type][500]};
      }
      svg {
        width: ${small ? "1rem" : "1.25rem"};
        height: ${small ? "1rem" : "1.25rem"};
        path {
          fill: ${getColorTypeMap(theme)[type][500]};
        }
        &:nth-of-type(2) {
          cursor: pointer;
          margin: -0.5rem;
          width: 1.5em;
          height: 1.5em;
        }
      }
      opacity: 0.8;
      border-radius: ${borderTypes.rounded};
      div {
        flex-grow: 1;
        gap: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p:first-of-type {
          line-height: 1.25rem !important;
        }
      }
    `;
export const learnMore = css `
  cursor: pointer;
`;

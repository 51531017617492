import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { $createAudioNode, AudioNode } from "../nodes/AudioNode";
export const INSERT_AUDIO_COMMAND = createCommand();
export default function AudioAttachmentPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([AudioNode])) {
            throw new Error("AudioPlugin: AudioNode not registered on editor");
        }
        return editor.registerCommand(INSERT_AUDIO_COMMAND, (payload) => {
            var _a;
            const audioNode = $createAudioNode((_a = payload.attachment) === null || _a === void 0 ? void 0 : _a.metadata, payload.fileName);
            $insertNodeToNearestRoot(audioNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}

export const TEXT_COLOR = {
    PRIMARY: "primaryText.0",
    SECONDARY: "secondaryText.0",
    PLACEHOLDER: "placeholderText.0",
    TERTIARY: "tertiaryText.0",
    INVERTED_PRIMARY: "invertedPrimaryText.0",
};
export const STATUS_COLOR = {
    INFORMATIVE: "informativeStatus.0",
    INFORMATIVE_WASH: "informativeWashStatus.0",
    AFFIRMATIVE: "affirmativeStatus.0",
    AFFIRMATIVE_WASH: "affirmativeWashStatus.0",
    WARNING: "warningStatus.0",
    WARNING_WASH: "warningWashStatus.0",
    ERROR: "errorStatus.0",
    ERROR_WASH: "errorWashStatus.0",
    SUCCESS: "successStatus.0",
    SUCCESS_WASH: "successWashStatus.0",
};
export const ACCENT_COLOR = {
    ACCENT: "accent.0",
    ACCENT_WASH: "accentWash.0",
};
export const BACKGROUND_COLOR = {
    BASE: "baseBackground.0",
    INVERTED_BASE: "invertedBaseBackground.0",
    PRIMARY: "primaryBackground.0",
    SECONDARY: "secondaryBackground.0",
    TERTIARY: "tertiaryBackground.0",
    QUATERNARY: "quaternaryBackground.0",
};
export const HIGHLIGHT_101_COLOR = {
    PRIMARY: "primaryBackgroundHighlight101.0",
    SECONDARY: "secondaryBackgroundHighlight101.0",
    TEXT: "textHighlight101.0",
    BORDER: "borderHighlight101.0",
};
export const UTILITY_COLOR = {
    DIVIDER: "divider.0",
    OVERLAY: "overlay.0",
    STRONG_OVERLAY: "strongOverlay.0",
    HERO_IMAGE_BACKGROUND: "heroImageBackground.0",
    TABLE_ROW_BACKGROUND: "tableRowBackground.0",
    DEPRECATION_WARN: "warningDeprecation.0",
};
export const CODE_SNIPPET = {
    CONTAINER_BACKGROUND: "codeSnippetContainerBackground.0",
};
export const rgbaToHex = (rgba) => {
    const match = rgba.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    if (!match) {
        return rgba;
    }
    const [, r, g, b, a] = match.map(parseFloat);
    // Ensure values are within valid ranges
    const red = Math.min(255, Math.max(0, r));
    const green = Math.min(255, Math.max(0, g));
    const blue = Math.min(255, Math.max(0, b));
    const alpha = Math.min(1, Math.max(0, a));
    // Convert to hex values
    const hexR = red.toString(16).padStart(2, "0");
    const hexG = green.toString(16).padStart(2, "0");
    const hexB = blue.toString(16).padStart(2, "0");
    const hexA = Math.round(alpha * 255)
        .toString(16)
        .padStart(2, "0");
    // Combine hex values
    return `#${hexR}${hexG}${hexB}${hexA}`;
};

import { css, Theme } from "@emotion/react";
import { bottomMargin } from "@hl/communities-app/lib/styles/global/layout";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const content = (theme: Theme) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
  padding: 0 0 64px 0;
  text-align: center;

  p {
    a {
      transition: color 0.6s ${theme.animations.easeOutQuart};
      color: ${theme.colorMap.accent.default};
      font-weight: ${theme.text.fontWeights.bold};

      @media (hover: hover) {
        &:hover {
          color: ${theme.colorMap.accent[600]};
        }
      }
    }
  }

  > a {
    max-width: 307px;
  }

  ${theme.mediaQueries.smallOnly} {
    padding: 0 0 40px 0;
  }
`;

export const title = css`
  ${bottomMargin("9/32px")};
`;

export const description = css`
  ${bottomMargin("11/48px")};
`;

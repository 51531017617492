import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Code as HTMLIcon } from "../../../../../../assets/icons.generated";
import { INSERT_HTML_TAG_COMMAND } from "../../HTMLTagAttachmentPlugin";
import BaseAttachment from "../BaseAttachment";
const HTMLAttachment = ({ onHTMLButtonClickHandler, postInsertHandler, }) => {
    const [editor] = useLexicalComposerContext();
    const insertHTMLTag = (html) => {
        editor.focus();
        editor.dispatchCommand(INSERT_HTML_TAG_COMMAND, { html });
        postInsertHandler();
    };
    const onButtonClickHandler = () => onHTMLButtonClickHandler(insertHTMLTag);
    return (_jsx(BaseAttachment, { title: "HTML", children: _jsx("button", { onClick: onButtonClickHandler, children: _jsx(HTMLIcon, {}) }) }));
};
export default memo(HTMLAttachment);

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { DecoratorBlockNode, } from "@lexical/react/LexicalDecoratorBlockNode";
import EditorActionsHandler from "../EditorActionsHandler";
import { PublishToPreview } from "../Placeholder";
import VeryDangerouslySetHtml from "../../../../VeryDangerouslySetHtml";
import { dispatchEditHTMLEmbedEvent } from "./events";
export class HTMLTagNode extends DecoratorBlockNode {
    static getType() {
        return "htmltag";
    }
    static clone(node) {
        return new HTMLTagNode(node.__html, node.__format, node.__key);
    }
    constructor(html, format, key) {
        super(format, key);
        this.__html = html;
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isHTMLTagNode(n), onEditClickHandler: () => dispatchEditHTMLEmbedEvent({
                nodeKey: this.getKey(),
                html: this.__html,
            }), children: _jsx(VeryDangerouslySetHtml, { html: this.__html }) }));
    }
    isInline() {
        return false;
    }
    static importJSON(serializedNode) {
        const node = new HTMLTagNode(serializedNode.html);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            html: this.__html,
            type: this.getType(),
            version: 1,
        };
    }
    setData(data) {
        const self = this.getWritable();
        self.__html = data;
    }
}
export class HTMLTagNodePreview extends HTMLTagNode {
    static getType() {
        return HTMLTagNode.getType();
    }
    static clone(node) {
        return new HTMLTagNodePreview(node.__html, node.__format, node.__key);
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isHTMLTagNode(n), onEditClickHandler: () => dispatchEditHTMLEmbedEvent({
                nodeKey: this.getKey(),
                html: this.__html,
            }), children: _jsx(PublishToPreview, {}) }));
    }
    static importJSON(serializedNode) {
        const node = new HTMLTagNodePreview(serializedNode.html);
        node.setFormat(serializedNode.format);
        return node;
    }
}
export class DisabledHTMLTagNode extends HTMLTagNode {
    static getType() {
        return HTMLTagNode.getType();
    }
    static clone(node) {
        return new DisabledHTMLTagNode(node.__html, node.__format, node.__key);
    }
    constructor(html, format, key) {
        super(html, format, key);
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isHTMLTagNode(n), children: _jsx("div", { children: "Embed temporarily unavailable" }) }));
    }
    static importJSON(serializedNode) {
        const node = new DisabledHTMLTagNode(serializedNode.html);
        node.setFormat(serializedNode.format);
        return node;
    }
}
export function $createHTMLTagNode(html, disabled, showEmbedNodes) {
    if (disabled) {
        return new DisabledHTMLTagNode(html);
    }
    if (showEmbedNodes) {
        return new HTMLTagNode(html);
    }
    return new HTMLTagNodePreview(html);
}
export function $isHTMLTagNode(node) {
    return node instanceof HTMLTagNode;
}

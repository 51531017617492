import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Error as ErrorIcon, InformationIcon as InfoIcon, RegularRoundedClose as CloseIcon, } from "../../assets/icons.generated";
import Text from "../Typography/Text";
import * as styles from "./styles";
const typeIconMap = {
    error: _jsx(ErrorIcon, {}),
    info: _jsx(InfoIcon, {}),
};
export const MessageBanner = ({ type = "error", title, learnMore, onClose, message, margin = true, small = false, }) => {
    if (!message)
        return null;
    return (_jsxs("div", { css: styles.container(type, margin, small), children: [typeIconMap[type], _jsxs("div", { children: [title && (_jsx(Text, { size: "large", bold: true, children: title })), _jsx(Text, { size: small ? "small" : "medium", children: message }), learnMore && (_jsx("div", { css: styles.learnMore, onClick: learnMore, children: _jsx(Text, { bold: true, children: "Learn More \u2192" }) }))] }), onClose && _jsx(CloseIcon, { onClick: onClose })] }));
};

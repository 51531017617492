import { useCallback, useEffect, useState } from "react";
import { useLocalStorage, useColorScheme as useMantineColorScheme, } from "@mantine/hooks";
import { ColorSchemeMode, UserColorSchemeSettings } from "./ColorSchemeContext";
export const useColorScheme = ({ userColorScheme, updateUserColorScheme, }) => {
    const [userColorSchemeSettings, setUserSelectedColorScheme] = useState(userColorScheme || UserColorSchemeSettings.LIGHT);
    const preferredColorScheme = useMantineColorScheme();
    const [colorSchemeLocalStore, setColorSchemeLocalStore] = useLocalStorage({
        key: "highlight-color-scheme",
        defaultValue: ColorSchemeMode.LIGHT,
        getInitialValueInEffect: true,
    });
    useEffect(() => {
        if (!userColorScheme)
            return;
        setUserSelectedColorScheme(userColorScheme);
        if (userColorScheme === UserColorSchemeSettings.SYSTEM) {
            setColorSchemeLocalStore(preferredColorScheme);
            return;
        }
        setColorSchemeLocalStore(userColorScheme === UserColorSchemeSettings.DARK
            ? ColorSchemeMode.DARK
            : ColorSchemeMode.LIGHT);
    }, [
        userColorScheme,
        preferredColorScheme,
        setColorSchemeLocalStore,
        setUserSelectedColorScheme,
    ]);
    const setColorScheme = useCallback(async (newColorScheme) => {
        setUserSelectedColorScheme(newColorScheme);
        if (newColorScheme === UserColorSchemeSettings.SYSTEM) {
            setColorSchemeLocalStore(preferredColorScheme);
        }
        else {
            setColorSchemeLocalStore(newColorScheme === UserColorSchemeSettings.DARK
                ? ColorSchemeMode.DARK
                : ColorSchemeMode.LIGHT);
        }
        if (updateUserColorScheme) {
            try {
                await updateUserColorScheme(newColorScheme);
            }
            catch (e) {
                console.log("Error saving user preferences");
            }
        }
    }, [
        setColorSchemeLocalStore,
        preferredColorScheme,
        updateUserColorScheme,
        setUserSelectedColorScheme,
    ]);
    return {
        userColorSchemeSettings,
        selectUserColorScheme: setColorScheme,
        colorScheme: colorSchemeLocalStore,
    };
};

import { css, Theme } from "@emotion/react";

export const editorContainer = (theme: Theme) =>
  css`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${theme.background.colors.primary};
  `;

export const editorContent = css`
  position: relative;
  min-height: 300px;
  cursor: text;
`;

export const editorInput = (theme: Theme) =>
  css`
    min-height: inherit;
    box-shadow: none;

    :focus-visible {
      outline: none;
    }

    > * {
      display: block;
      margin-block: 1.25rem;

      &:first-of-type {
        margin-block-start: 0;
      }

      &:last-child {
        margin-block-end: 0;
      }
    }

    // Headings
    h1 {
      font-weight: 500;
      font-size: 32px;
      line-height: 1.2em;
      letter-spacing: -0.032em;
      font-family: ${theme.page.title.fontFamily};
    }
    ,
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.2em;
      letter-spacing: -0.024em;
      font-family: ${theme.page.title.fontFamily};
    }
    ,
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      font-family: ${theme.page.title.fontFamily};
    }

    // Paragraphs
    p,
    ul,
    strong,
    b {
      a {
        color: inherit;
        text-decoration: underline;
      }
      font-weight: 400;
      font-size: 14px;
      line-height: 1.4em;
      font-family: ${theme.page.text.fontFamily};
    }

    strong,
    b {
      font-weight: 600;
    }

    // Inline Styles
    .editor-text-bold {
      font-weight: 600;
    }

    .editor-text-italic {
      font-style: italic;
    }

    &[contenteditable="true"] {
      > h1,
      > h2,
      > h3,
      > p {
        cursor: text;
      }
    }
  `;

export const editorPlaceholder = css`
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

import { PropsWithChildren } from "react";

import { css, Theme } from "@emotion/react";

import { BackgroundType } from "apollo/graphql.generated";

const backgroundCss = (hide?: boolean) => (theme: Theme) => {
  if (hide) return null;
  const bg = theme.page.background;
  const bgCss =
    bg.type == BackgroundType.Gradient
      ? css`
          background: linear-gradient(${bg.color}, ${bg.bottomColor});
        `
      : css`
          background-color: ${bg.color};
        `;

  return bgCss;
};

const baseStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Background: React.FC<
  PropsWithChildren<{
    hide?: boolean;
  }>
> = ({ children, hide }) => (
  <div css={[backgroundCss(hide), baseStyles]}>{children}</div>
);

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useRef } from "react";
import { css } from "@emotion/react";
import { Player } from "react-simple-player";
import { layoutContext } from "../../../apollo/reactive/layout";
import { parseBorderType } from "../../../styles/theme/communityTheme";
import * as systemTextStyles from "../../Typography/Text/styles";
/**
 * Styles
 */
const styles = {
    nodeWrapper: (pageTheme) => (theme) => css `
      position: relative;

      // Wrapper
      > span {
        border-radius: ${theme.cardContainer.borderRadius.medium};
        padding: 20px 20px 20px 18px;
        background: ${pageTheme !== "community"
        ? theme.background.colors.tertiary
        : theme.community.secondaryBackground.backgroundColor} !important;

        // Play Button
        > button:nth-of-type(1) {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          flex-shrink: 0;
          justify-content: center;
          padding: 0;

          svg {
            path {
              fill: currentColor;
            }
          }

          ${pageTheme === "community"
        ? css `
                border-style: solid;
                background-color: ${theme.community.button
            .onPrimaryBackgroundBackgroundColor};
                color: ${theme.community.button.onPrimaryBackgroundTextColor};
                border-color: ${theme.community.button
            .onPrimaryBackgroundBorderColor};
                border-width: ${theme.community.button
            .onPrimaryBackgroundBorderWidth};

                border-radius: ${parseBorderType(theme.community.button.borderType)};
                background: ${theme.community.button
            .onPrimaryBackgroundBackgroundColor};
              `
        : css `
                color: ${theme.invertedText.colors.primary};
                background: ${theme.text.colors.primary};
              `}
        }

        // Timeline
        > span:nth-of-type(1) {
          padding: 0 12px !important;
          cursor: pointer;

          > span:first-of-type {
            border-radius: 10px;

            span {
              &:nth-of-type(1) {
                background: rgba(0, 0, 0, 0.1) !important;
                border-radius: 10px;
              }

              &:nth-of-type(2) {
                background: rgba(0, 0, 0, 0.1) !important;
                border-radius: 10px;
                display: none;
              }

              &:nth-of-type(3),
              &:nth-of-type(4) {
                background: ${pageTheme === "community"
        ? theme.community.button.onPrimaryBackgroundBackgroundColor
        : theme.text.colors.primary} !important;
              }
            }
          }

          span:nth-of-type(2) {
            transform: translateY(-10px);
          }
        }

        // Time
        > span:nth-of-type(2) {
          ${systemTextStyles.bySize("medium", false, false)(theme)}
          ${systemTextStyles.bySize("small", false, false)(theme)};
          padding: 0 12px 0 0 !important;
          font-variant-numeric: tabular-nums;
          color: ${pageTheme === "community"
        ? theme.community.button.onPrimaryBackgroundBackgroundColor
        : theme.text.colors.primary} !important;
        }

        // Mute Button
        > button:nth-of-type(2) {
          padding: 0;
          background: transparent;
          color: ${pageTheme === "community"
        ? theme.community.button.onPrimaryBackgroundBackgroundColor
        : theme.text.colors.primary};

          svg {
            path,
            polygon {
              fill: currentColor;
            }
          }
        }
      }
    `,
    audio: css `
    width: 100%;
  `,
};
/**
 * Helpers
 */
const isMediaMetadata = (toBeDefined) => {
    if (!toBeDefined)
        return false;
    return toBeDefined.mime !== undefined;
};
const AudioAttachment = ({ attachment }) => {
    const { theme } = layoutContext();
    const ref = useRef(null);
    if (!isMediaMetadata(attachment) || !attachment.url) {
        return null;
    }
    return (_jsx("div", { css: styles.nodeWrapper(theme !== null && theme !== void 0 ? theme : "system"), ref: ref, children: _jsx(Player, { hideVolume: true, src: attachment.url, height: 96 }) }));
};
export default AudioAttachment;

import { useEffect } from "react";

import { DocumentNode, useReactiveVar } from "@apollo/client";
import { GateConditionsFailed } from "@hl/shared-features/lib/features/gate/GateConditionsFailed";
import { GateConditionsModalProps } from "@hl/shared-features/lib/features/gate/GateConditionsModalBody";
import { GateConditionsResult } from "@hl/shared-features/lib/features/gate/GateConditionsResult";

import { UnlockGateResult } from "apollo/graphql.generated";
import { GatedEntityAllPrereqsModalProps } from "~/features/dashboard/GatedEntityAllPrereqsModal";
import {
  ModalType,
  modalVar,
  resetModalVar,
  setModalVar,
  updateModalVar,
} from "~/features/layout/Modal/modal";

import PageText from "../../../components/Page/Text";

import * as styles from "./styles";

type LockedPageProps = {
  isPreview?: boolean;
  lockedMessage?: string | null;
  unlockGateResult?: UnlockGateResult;
  creatorAddress?: string | null;
  creatorEns?: string | null;
  refetchOnRequirementChange?: DocumentNode[];
};

export const LockedPage = ({
  lockedMessage,
  isPreview,
  unlockGateResult,
  creatorAddress,
  creatorEns,
  refetchOnRequirementChange,
}: LockedPageProps) => {
  const { showModal } = useReactiveVar(
    modalVar as GatedEntityAllPrereqsModalProps
  );

  // Needed to update modal data on refetch (unlockGateResult)
  useEffect(() => {
    if (showModal === ModalType.GATED_ENTITY_ALL_PREREQS && unlockGateResult) {
      setModalVar<GateConditionsModalProps>({
        showModal: ModalType.GATED_ENTITY_ALL_PREREQS,
        data: {
          creatorAddress,
          creatorEns,
          refetchOnRequirementChange,
          result: unlockGateResult,
          handleDone: () => resetModalVar(),
          entity: "page",
          isPreview: !!isPreview,
        },
      });
    }
  }, [showModal, unlockGateResult, creatorAddress, creatorEns]);

  const showAllGatePrereqs = (
    result: UnlockGateResult,
    creatorAddress?: string | null,
    creatorEns?: string | null
  ) => {
    return () => {
      updateModalVar<GateConditionsModalProps>({
        showModal: ModalType.GATED_ENTITY_ALL_PREREQS,
        data: {
          creatorAddress,
          creatorEns,
          result,
          handleDone: () => resetModalVar(),
          entity: "page",
          isPreview: !!isPreview,
        },
      });
    };
  };

  return (
    <div css={styles.container}>
      {lockedMessage && (
        <PageText extraCss={styles.lockedMessage}>{lockedMessage}</PageText>
      )}
      <>
        {unlockGateResult ? (
          isPreview ? (
            <GateConditionsFailed
              creatorAddress={creatorAddress}
              creatorEns={creatorEns}
              refetchOnRequirementChange={refetchOnRequirementChange}
              unlockGateResult={unlockGateResult}
              handleShowModal={showAllGatePrereqs(
                unlockGateResult,
                creatorAddress,
                creatorEns
              )}
              isPreview
              entity="page"
            />
          ) : (
            <GateConditionsResult
              creatorAddress={creatorAddress}
              creatorEns={creatorEns}
              refetchOnRequirementChange={refetchOnRequirementChange}
              handleShowModal={showAllGatePrereqs(
                unlockGateResult,
                creatorAddress,
                creatorEns
              )}
              unlockGateResult={unlockGateResult}
              entity="page"
              headerSize="lg"
            />
          )
        ) : null}
      </>
    </div>
  );
};

import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type PageThemeFieldsFragment = {
  __typename: "PageTheme";
  background: {
    __typename: "PageThemeBackground";
    type: Types.BackgroundType;
    color: string;
    bottomColor: string | null;
  };
  text: { __typename: "PageThemeText"; color: string; fontFamily: string };
  title: { __typename: "PageThemeTitle"; fontFamily: string };
  corners: { __typename: "PageThemeCorners"; shape: Types.CornersShape };
};

export type PageViewQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type PageViewQuery = {
  pageView: {
    __typename: "PublicPage";
    isLocked: boolean;
    gate: {
      __typename: "Gate";
      id: string;
      lockedMessage: string | null;
    } | null;
    page: {
      __typename: "Page";
      id: string;
      slug: string;
      status: Types.PageStatus;
      created: string;
      sections: Array<{
        __typename: "PageSection";
        id: string;
        type: Types.SectionType;
        content: {
          __typename: "PageContent";
          editorContent: { __typename: "EditorContent"; state: string } | null;
          mintContent: {
            __typename: "MintContent";
            id: string | null;
            type: Types.MintIdType;
            attributes: {
              __typename: "MintAttributes";
              params: string;
              onChain: boolean;
            };
          } | null;
        };
      }>;
    } | null;
    theme: {
      __typename: "PageTheme";
      background: {
        __typename: "PageThemeBackground";
        type: Types.BackgroundType;
        color: string;
        bottomColor: string | null;
      };
      text: { __typename: "PageThemeText"; color: string; fontFamily: string };
      title: { __typename: "PageThemeTitle"; fontFamily: string };
      corners: { __typename: "PageThemeCorners"; shape: Types.CornersShape };
    } | null;
    creator: {
      __typename: "PublicAccount";
      evmAddresses: Array<string>;
      ensName: string | null;
    } | null;
    unlockGateResult: {
      __typename: "UnlockGateResult";
      passed: boolean;
      type: string | null;
      gateOwnerId: string | null;
      failureReasons: Array<{
        __typename: "FailureReason";
        code: Types.FailureReasonCode;
        reason: string | null;
      }> | null;
      conditionResults: Array<{
        __typename: "ConditionResult";
        type: Types.ConditionType | null;
        selector: string | null;
        required: boolean | null;
        amount: number | null;
        autoBuyButton: Types.BuyMarketplace | null;
        buyButtonUrl: string | null;
        category: string | null;
        passed: boolean;
        userAmount: number | null;
        failureReason: {
          __typename: "FailureReason";
          code: Types.FailureReasonCode;
          reason: string | null;
        } | null;
        data: {
          __typename: "GenericConditionData";
          network: Types.NetworkType | null;
          address: string | null;
          tokenId: string | null;
          allowlistLength: number | null;
          attributes: Array<{
            __typename: "Attribute";
            key: string;
            value: Array<string>;
          }> | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export const PageThemeFieldsFragmentDoc = gql`
  fragment PageThemeFields on PageTheme {
    background {
      type
      color
      bottomColor
    }
    text {
      color
      fontFamily
    }
    title {
      fontFamily
    }
    corners {
      shape
    }
  }
`;
export const PageViewDocument = gql`
  query PageView($slug: String!) {
    pageView(slug: $slug) {
      isLocked
      gate {
        id
        lockedMessage
      }
      page {
        id
        slug
        sections {
          id
          type
          content {
            editorContent {
              state
            }
            mintContent {
              id
              type
              attributes {
                params
                onChain
              }
            }
          }
        }
        status
        created
      }
      theme {
        ...PageThemeFields
      }
      creator {
        evmAddresses
        ensName
      }
      unlockGateResult {
        passed
        type
        failureReasons {
          code
          reason
        }
        gateOwnerId
        conditionResults {
          type
          selector
          required
          amount
          failureReason {
            code
            reason
          }
          data {
            network
            address
            tokenId
            attributes {
              key
              value
            }
            allowlistLength
          }
          autoBuyButton
          buyButtonUrl
          category
          passed
          userAmount
        }
      }
    }
  }
  ${PageThemeFieldsFragmentDoc}
`;

/**
 * __usePageViewQuery__
 *
 * To run a query within a React component, call `usePageViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageViewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageViewQuery(
  baseOptions: Apollo.QueryHookOptions<PageViewQuery, PageViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PageViewQuery, PageViewQueryVariables>(
    PageViewDocument,
    options
  );
}
export function usePageViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageViewQuery,
    PageViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PageViewQuery, PageViewQueryVariables>(
    PageViewDocument,
    options
  );
}
export type PageViewQueryHookResult = ReturnType<typeof usePageViewQuery>;
export type PageViewLazyQueryHookResult = ReturnType<
  typeof usePageViewLazyQuery
>;
export type PageViewQueryResult = Apollo.QueryResult<
  PageViewQuery,
  PageViewQueryVariables
>;

import { css, Theme } from "@emotion/react";
import { bottomMargin } from "@hl/communities-app/lib/styles/global/layout";

export const toolbar = (theme: Theme) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  top: 68px;
  z-index: ${theme.zLayers.editor};

  background: ${theme.background.colors.primary};
  padding: 8px 16px 8px 4px;
  border-radius: 10px;
  border: 1px solid ${theme.colorMap.utility.divider};

  ${bottomMargin("9/32px")};

  ${theme.mediaQueries.mediumUp} {
    justify-content: space-between;
    position: sticky;
  }
`;

export const leftBar = css`
  display: flex;
`;

export const headingSelector = css`
  margin-right: 32px;
`;

export const pluginGroup = css`
  display: flex;
  gap: 6px;
`;

export const rightBar = css`
  display: flex;
  gap: 12px;
  align-items: center;
`;

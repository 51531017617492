import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { css } from "@emotion/react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Pencil as EditIcon } from "../../../../assets/icons.generated";
import AttachmentFloatingButton from "./AttachmentFloatingButton";
const AttachmentEditButton = ({ onEditClickHandler, }) => {
    const [editor] = useLexicalComposerContext();
    if (!editor.isEditable())
        return null;
    return (_jsx(AttachmentFloatingButton, { icon: _jsx(EditIcon, { css: (theme) => css `
              path {
                stroke: ${theme.colorMap.accent.default};
              }
            ` }), onClick: onEditClickHandler, children: "Edit" }));
};
export default memo(AttachmentEditButton);

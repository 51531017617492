import { css } from "@emotion/react";
export const containerStyles = (background, paletteBackground, padding, flushHorizontal, shadow) => (theme) => css `
      padding: ${typeof padding === "number"
    ? `${padding}px`
    : theme.cardContainer.padding[padding]};
      ${flushHorizontal && `padding-left: 0`};
      ${flushHorizontal && `padding-right: 0`};
      background-color: ${paletteBackground
    ? theme.colorMap[paletteBackground.color][paletteBackground.shade]
    : theme.background.colors[background]};
      ${shadow && `box-shadow: ${theme.shadows[shadow]};`};
    `;
export const borderStyle = (border, borderRadius, borderColor, borderWidth) => (theme) => {
    return css `
      border-radius: ${theme.cardContainer.borderRadius[borderRadius || "medium"]};
      ${optionalBorderStyle(border, borderColor, borderWidth)(theme)}
    `;
};
const optionalBorderStyle = (border, borderColor, borderWidth) => (theme) => {
    if (border || borderWidth || borderColor) {
        return css `
        border-style: solid;
        border-width: ${borderWidth ? `${borderWidth}px` : "1px"};
        border-color: ${!borderColor || borderColor === "divider"
            ? theme.divider
            : theme.colorMap[borderColor].primary};
      `;
    }
};

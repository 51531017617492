import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { SafeInnerHTML } from "../../SafeInnerHTML";
import * as styles from "./styles";
const SystemText = ({ additionalCss, extraCss, size = "medium", color = "primary", as = "p", innerHTML = false, onBackgroundColor, inverted = false, bold = false, uppercase = false, textAlign, children, ...rest }) => {
    const Tag = as;
    return (_jsx(Tag, { css: [
            styles.bySize(size, bold, uppercase),
            styles.byColor(color, inverted),
            onBackgroundColor && styles.byBackgroundTextColor(onBackgroundColor),
            innerHTML && styles.byInnerHTML,
            textAlign && styles.textAlign(textAlign),
            additionalCss,
            extraCss,
        ], ...rest, children: children }));
};
export const RichText = ({ text }) => {
    if (!text) {
        return null;
    }
    if (text.match(/\<\b/)) {
        return _jsx(SafeInnerHTML, { as: "span", html: text });
    }
    return _jsx("div", { css: styles.presLineBreaks, children: text });
};
export default memo(SystemText);

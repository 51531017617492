import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Dotpoints as ListIcon } from "../../../../../../assets/icons.generated";
import { textStylesButton } from "../styles";
const BulletListToggle = ({ blockType, dark = false }) => {
    const [activeEditor] = useLexicalComposerContext();
    const formatBulletList = () => {
        if (blockType !== "bullet") {
            activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }
        else {
            activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };
    return (_jsx("button", { css: textStylesButton(dark, blockType === "bullet"), onClick: formatBulletList, children: _jsx(ListIcon, {}) }));
};
export default memo(BulletListToggle);

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import * as systemStyles from "./styles";
const SystemTitle = ({ children, size = "medium", color = "primary", as = "h2", inverted = false, centered = false, onBackgroundColor, extraCss, additionalCss, ...rest }) => {
    const Tag = as;
    return (_jsx(Tag, { css: [
            systemStyles.base,
            systemStyles.bySize(size),
            systemStyles.byColor(color, inverted),
            onBackgroundColor &&
                systemStyles.byBackgroundTextColor(onBackgroundColor),
            centered ? "text-align: center;" : null,
            additionalCss ? additionalCss : null,
            extraCss,
        ], ...rest, children: children }));
};
export default memo(SystemTitle);

import { memo } from "react";

import { Text, Title } from "../../../components/Typography";
import Layout from "../Layout";
import { AppSEO } from "../Layout/SEO";

import * as styles from "./styles";

const FeatureNotAvailable = () => (
  <>
    <AppSEO title="Page not found" />

    <Layout css={styles.container}>
      <div css={styles.content}>
        <Title size="large" extraCss={styles.title}>
          This Page Is Temporarily Not Available
        </Title>
        <Text extraCss={styles.description}></Text>
      </div>
    </Layout>
  </>
);

export default memo(FeatureNotAvailable);

import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { $createImageNode, ImageNode } from "../nodes/ImageNode";
export const INSERT_IMAGE_COMMAND = createCommand();
export default function ImageAttachmentPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error("ImagePlugin: ImageNode not registered on editor");
        }
        return editor.registerCommand(INSERT_IMAGE_COMMAND, (payload) => {
            var _a;
            const imageNode = $createImageNode((_a = payload.attachment) === null || _a === void 0 ? void 0 : _a.metadata, payload.fileName);
            $insertNodeToNearestRoot(imageNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}

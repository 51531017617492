import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { DecoratorBlockNode, } from "@lexical/react/LexicalDecoratorBlockNode";
import { AttachmentType, } from "../../../../apollo/graphql.generated";
import VideoAttachment from "../../Attachments/Video";
import EditorActionsHandler from "./EditorActionsHandler";
export class VideoNode extends DecoratorBlockNode {
    static getType() {
        return "video";
    }
    static clone(node) {
        return new VideoNode(node.__attachment, node.__attachmentFileName, node.__format, node.__key);
    }
    constructor(attachment, fileName, format, key) {
        super(format, key);
        this.__attachment = attachment;
        this.__attachmentFileName = fileName;
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isVideoNode(n), children: _jsx(VideoAttachment, { attachment: this.__attachment }) }));
    }
    isInline() {
        return false;
    }
    static importJSON(serializedNode) {
        const node = $createVideoNode({
            mime: serializedNode.mediaMime,
            type: serializedNode.mediaType,
            url: serializedNode.mediaUrl,
        }, serializedNode.attachmentName);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        var _a;
        return {
            ...super.exportJSON(),
            mediaMime: this.__attachment.mime,
            mediaType: this.__attachment.type,
            mediaUrl: (_a = this.__attachment.url) !== null && _a !== void 0 ? _a : "",
            attachmentType: AttachmentType.MEDIA,
            attachmentName: this.__attachmentFileName,
            type: this.getType(),
            version: 1,
        };
    }
}
export function $createVideoNode(attachment, fileName) {
    return new VideoNode(attachment, fileName);
}
export function $isVideoNode(node) {
    return node instanceof VideoNode;
}

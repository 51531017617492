import { fontFamilyMap } from "@hl/communities-app/lib/styles/global/fonts";

import { PageTheme } from "apollo/graphql.generated";

import { defaultTheme } from "./theme";

export type Theme = {
  backgroundType: string;
  backgroundColor: string;
  backgroundBottomColor?: string | null;
  textColor: string;
  titleFont: string;
  bodyFont: string;
  cornerShape: string;
};

const findFont = (name: string) => fontFamilyMap[name] ?? name;

export const createThemeForPage = (theme: PageTheme) => {
  return {
    ...defaultTheme,
    page: {
      ...theme,
      text: {
        ...theme.text,
        fontFamily: findFont(theme.text.fontFamily),
      },
      title: {
        ...theme.title,
        fontFamily: findFont(theme.title.fontFamily),
      },
    },
  };
};

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, memo, useCallback, useEffect, useState, } from "react";
import _uniqueId from "lodash/uniqueId";
import Text from "../../Typography/Text";
import Title from "../../Typography/Title";
import * as styles from "./styles";
const TextArea = forwardRef(({ error, id, label = "", labelType = "text", labelOnBackgroundColor, rows = 5, initialLength = 0, placeholderPrefix, placeholderPostfix, additionalCss, ...rest }, ref) => {
    const htmlFor = _uniqueId("field-");
    const [lengthCount, setLengthCount] = useState(0);
    useEffect(() => {
        setLengthCount(initialLength);
    }, [initialLength]);
    const handleLengthCount = useCallback((e) => {
        setLengthCount(e.currentTarget.value.length);
    }, []);
    const { maxLength } = rest;
    if (maxLength) {
        rest.onKeyUp = handleLengthCount;
    }
    const renderTitle = () => {
        switch (labelType) {
            case "text":
                return (_jsx(Text, { size: "medium", bold: true, onBackgroundColor: labelOnBackgroundColor, children: label }));
            case "title":
                return (_jsx(Title, { size: "xsmall", onBackgroundColor: labelOnBackgroundColor, children: label }));
        }
    };
    return (_jsxs("div", { css: [styles.fieldGroup, additionalCss], children: [_jsx("label", { htmlFor: id !== null && id !== void 0 ? id : htmlFor, css: styles.label, children: renderTitle() }), _jsxs("div", { css: styles.inputWrapper(error), children: [placeholderPrefix && (_jsx("span", { css: styles.prefix, children: placeholderPrefix })), _jsx("textarea", { ref: ref, id: id !== null && id !== void 0 ? id : htmlFor, css: styles.input(error, !!placeholderPrefix), rows: rows, ...rest }), placeholderPostfix && (_jsx("span", { css: styles.postfix, children: placeholderPostfix })), maxLength && (_jsx("p", { css: styles.counter, children: _jsxs(Text, { as: "span", size: "small", color: "secondary", children: [lengthCount, "/", maxLength] }) }))] }), error && _jsx("p", { css: styles.error, children: error })] }));
});
export default memo(TextArea);

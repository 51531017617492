import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { $createEmbedTagNode, EmbedTagNode } from "../nodes/EmbedTagNode";
export const INSERT_EMBED_TAG_COMMAND = createCommand();
export default function EmbedTagAttachmentPlugin({ showEmbedNodes, }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([EmbedTagNode])) {
            throw new Error("EmbedTagPlugin: EmbedTagNode not registered on editor");
        }
        return editor.registerCommand(INSERT_EMBED_TAG_COMMAND, (payload) => {
            const embedTagNode = $createEmbedTagNode(payload.url, showEmbedNodes);
            $insertNodeToNearestRoot(embedTagNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}

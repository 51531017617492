/** App Features */
export var AppFeature;
(function (AppFeature) {
    AppFeature["TokenGate"] = "TokenGate";
    AppFeature["TokenMint"] = "TokenMint";
})(AppFeature || (AppFeature = {}));
/** Type of Post Attachment. */
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType["MEDIA"] = "MEDIA";
    AttachmentType["TOKEN"] = "TOKEN";
})(AttachmentType || (AttachmentType = {}));
/** Theme background type */
export var BackgroundType;
(function (BackgroundType) {
    BackgroundType["Gradient"] = "Gradient";
    BackgroundType["Solid"] = "Solid";
})(BackgroundType || (BackgroundType = {}));
/** Type of Benefit for the Token. */
export var BenefitType;
(function (BenefitType) {
    BenefitType["CONTENT_FEED"] = "CONTENT_FEED";
    BenefitType["CUSTOM"] = "CUSTOM";
    BenefitType["DISCORD"] = "DISCORD";
    BenefitType["RELEASE_PARTY"] = "RELEASE_PARTY";
    BenefitType["SHOPIFY"] = "SHOPIFY";
})(BenefitType || (BenefitType = {}));
/** Border type options for the community theme */
export var BorderType;
(function (BorderType) {
    BorderType["Pill"] = "Pill";
    BorderType["Rounded"] = "Rounded";
    BorderType["Straight"] = "Straight";
})(BorderType || (BorderType = {}));
/** Type of Marketplace to Buy on */
export var BuyMarketplace;
(function (BuyMarketplace) {
    BuyMarketplace["OpenSea"] = "OpenSea";
})(BuyMarketplace || (BuyMarketplace = {}));
/** Contract type to deploy */
export var CollectionContractType;
(function (CollectionContractType) {
    CollectionContractType["Editions"] = "Editions";
    CollectionContractType["EditionsSingle"] = "EditionsSingle";
    CollectionContractType["General"] = "General";
})(CollectionContractType || (CollectionContractType = {}));
/** Collection Standard Type */
export var CollectionStandardType;
(function (CollectionStandardType) {
    CollectionStandardType["OPENSEA"] = "OPENSEA";
})(CollectionStandardType || (CollectionStandardType = {}));
/** Status of the collection's deployment */
export var CollectionStatus;
(function (CollectionStatus) {
    CollectionStatus["DRAFT"] = "DRAFT";
    CollectionStatus["IN_PROGRESS"] = "IN_PROGRESS";
    CollectionStatus["LIVE"] = "LIVE";
})(CollectionStatus || (CollectionStatus = {}));
/** Community Notification Type */
export var CommunityNotificationType;
(function (CommunityNotificationType) {
    CommunityNotificationType["CONTRACT"] = "CONTRACT";
})(CommunityNotificationType || (CommunityNotificationType = {}));
/** The status of the community. */
export var CommunityStatus;
(function (CommunityStatus) {
    CommunityStatus["FROZEN"] = "FROZEN";
    CommunityStatus["IN_PROGRESS"] = "IN_PROGRESS";
    CommunityStatus["PENDING_FIX"] = "PENDING_FIX";
    CommunityStatus["PREVIEW"] = "PREVIEW";
    CommunityStatus["PUBLIC"] = "PUBLIC";
})(CommunityStatus || (CommunityStatus = {}));
/** Type of Conditional */
export var ConditionalType;
(function (ConditionalType) {
    ConditionalType["ALLOWLIST"] = "ALLOWLIST";
    ConditionalType["ATTRIBUTE"] = "ATTRIBUTE";
    ConditionalType["COLLECTION"] = "COLLECTION";
    ConditionalType["CURRENCY"] = "CURRENCY";
    ConditionalType["NFT"] = "NFT";
})(ConditionalType || (ConditionalType = {}));
/** The type of contract. */
export var ContractType;
(function (ContractType) {
    ContractType["Community"] = "Community";
    ContractType["TokenManager"] = "TokenManager";
})(ContractType || (ContractType = {}));
/** Theme Corners shape */
export var CornersShape;
(function (CornersShape) {
    CornersShape["Rounded"] = "Rounded";
    CornersShape["Square"] = "Square";
})(CornersShape || (CornersShape = {}));
/** Sort value for Creator Community Members. */
export var CreatorCommunityMembersSort;
(function (CreatorCommunityMembersSort) {
    CreatorCommunityMembersSort["BY_DATE"] = "BY_DATE";
    CreatorCommunityMembersSort["BY_DATE_REVERSE"] = "BY_DATE_REVERSE";
    CreatorCommunityMembersSort["BY_TOKENS_AMOUNT"] = "BY_TOKENS_AMOUNT";
    CreatorCommunityMembersSort["BY_TOKENS_AMOUNT_REVERSE"] = "BY_TOKENS_AMOUNT_REVERSE";
})(CreatorCommunityMembersSort || (CreatorCommunityMembersSort = {}));
/** Currency Standard Type */
export var CurrencyStandardType;
(function (CurrencyStandardType) {
    CurrencyStandardType["ERC20"] = "ERC20";
    CurrencyStandardType["NATIVE"] = "NATIVE";
})(CurrencyStandardType || (CurrencyStandardType = {}));
/** Type of entity or object the associated entity-id uniquely identifies. */
export var EntityType;
(function (EntityType) {
    EntityType["POST"] = "POST";
    EntityType["RELEASE"] = "RELEASE";
})(EntityType || (EntityType = {}));
/** Event Type */
export var EventType;
(function (EventType) {
    EventType["CompleteSignedMessage"] = "CompleteSignedMessage";
    EventType["ConnectWallet"] = "ConnectWallet";
    EventType["CreateGateEditor_EditContent"] = "CreateGateEditor_EditContent";
    EventType["CreateGateEditor_EditTheme"] = "CreateGateEditor_EditTheme";
    EventType["CreateGateEditor_ImportToken"] = "CreateGateEditor_ImportToken";
    EventType["CreateGateEditor_PublishClick"] = "CreateGateEditor_PublishClick";
    EventType["CreateGateEditor_SetConditions"] = "CreateGateEditor_SetConditions";
    EventType["CreatedGate"] = "CreatedGate";
    EventType["CreatedGate_HasAudioContentType"] = "CreatedGate_HasAudioContentType";
    EventType["CreatedGate_HasEmbedContentType"] = "CreatedGate_HasEmbedContentType";
    EventType["CreatedGate_HasHTMLContentType"] = "CreatedGate_HasHTMLContentType";
    EventType["CreatedGate_HasImageContentType"] = "CreatedGate_HasImageContentType";
    EventType["CreatedGate_HasTextContentType"] = "CreatedGate_HasTextContentType";
    EventType["CreatedGate_HasVideoContentType"] = "CreatedGate_HasVideoContentType";
    EventType["DeployCollection_ArweaveUploadComplete"] = "DeployCollection_ArweaveUploadComplete";
    EventType["DeployCollection_ArweaveUploadFailed"] = "DeployCollection_ArweaveUploadFailed";
    EventType["DeployCollection_CompletedSuccessfully"] = "DeployCollection_CompletedSuccessfully";
    EventType["DeployCollection_Deploy_Button_Clicked"] = "DeployCollection_Deploy_Button_Clicked";
    EventType["DeployCollection_MediaSelectedAudioType"] = "DeployCollection_MediaSelectedAudioType";
    EventType["DeployCollection_MediaSelectedImageType"] = "DeployCollection_MediaSelectedImageType";
    EventType["DeployCollection_MediaSelectedVideoType"] = "DeployCollection_MediaSelectedVideoType";
    EventType["DeployCollection_TransactionSigned"] = "DeployCollection_TransactionSigned";
    EventType["DeployCollection_Viewed"] = "DeployCollection_Viewed";
    EventType["DeployCollection_Web2ObjectsCreatedSuccessfully"] = "DeployCollection_Web2ObjectsCreatedSuccessfully";
    EventType["DeployCollection_Web2ObjectsFailedtoCreate"] = "DeployCollection_Web2ObjectsFailedtoCreate";
    EventType["EditToken_ChangeGateModalOpened"] = "EditToken_ChangeGateModalOpened";
    EventType["EditToken_ChangeGateSaved"] = "EditToken_ChangeGateSaved";
    EventType["EditToken_EditSaleModalOpened"] = "EditToken_EditSaleModalOpened";
    EventType["EditToken_EditSaleSaved"] = "EditToken_EditSaleSaved";
    EventType["EditToken_PauseSale"] = "EditToken_PauseSale";
    EventType["EditToken_UnpauseSale"] = "EditToken_UnpauseSale";
    EventType["EditToken_Viewed"] = "EditToken_Viewed";
    EventType["ExistingGateEditor"] = "ExistingGateEditor";
    EventType["TokenSalePage_MintButtonClicked"] = "TokenSalePage_MintButtonClicked";
    EventType["TokenSalePage_TransactionFailed"] = "TokenSalePage_TransactionFailed";
    EventType["TokenSalePage_TransactionSigned"] = "TokenSalePage_TransactionSigned";
    EventType["TokenSalePage_TransactionSucceeded"] = "TokenSalePage_TransactionSucceeded";
    EventType["TokenSalePage_Viewed"] = "TokenSalePage_Viewed";
    EventType["ViewGatedPage_Locked"] = "ViewGatedPage_Locked";
    EventType["ViewGatedPage_Unlocked"] = "ViewGatedPage_Unlocked";
})(EventType || (EventType = {}));
/** Mint ID Type */
export var MintIdType;
(function (MintIdType) {
    MintIdType["ContractAddress"] = "ContractAddress";
    MintIdType["Vector"] = "Vector";
})(MintIdType || (MintIdType = {}));
/** Status of the Mint Vector */
export var MintVectorStatus;
(function (MintVectorStatus) {
    MintVectorStatus["Ended"] = "Ended";
    MintVectorStatus["InProgress"] = "InProgress";
    MintVectorStatus["NotStarted"] = "NotStarted";
})(MintVectorStatus || (MintVectorStatus = {}));
/** NFT Standard Type */
export var NftStandardType;
(function (NftStandardType) {
    NftStandardType["ERC721"] = "ERC721";
    NftStandardType["ERC1155"] = "ERC1155";
})(NftStandardType || (NftStandardType = {}));
/** Blockchain Network Types */
export var NetworkType;
(function (NetworkType) {
    NetworkType["ARBITRUM"] = "ARBITRUM";
    NetworkType["ARBITRUM_GOERLI"] = "ARBITRUM_GOERLI";
    NetworkType["ARBITRUM_RINKEBY"] = "ARBITRUM_RINKEBY";
    NetworkType["ETHEREUM"] = "ETHEREUM";
    NetworkType["FOUNDRY"] = "FOUNDRY";
    NetworkType["GOERLI"] = "GOERLI";
    NetworkType["HARDHAT"] = "HARDHAT";
    NetworkType["KOVAN"] = "KOVAN";
    NetworkType["LOCALHOST"] = "LOCALHOST";
    NetworkType["MUMBAI"] = "MUMBAI";
    NetworkType["OPTIMISM"] = "OPTIMISM";
    NetworkType["OPTIMISM_GOERLI"] = "OPTIMISM_GOERLI";
    NetworkType["OPTIMISM_KOVAN"] = "OPTIMISM_KOVAN";
    NetworkType["POLYGON"] = "POLYGON";
    NetworkType["RINKEBY"] = "RINKEBY";
    NetworkType["ROPSTEN"] = "ROPSTEN";
    NetworkType["SEPOLIA"] = "SEPOLIA";
})(NetworkType || (NetworkType = {}));
/** Notification Status */
export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["FAILURE"] = "FAILURE";
    NotificationStatus["SUCCESS"] = "SUCCESS";
})(NotificationStatus || (NotificationStatus = {}));
/** Type of Page Attachment. */
export var PageAttachmentType;
(function (PageAttachmentType) {
    PageAttachmentType["MEDIA"] = "MEDIA";
})(PageAttachmentType || (PageAttachmentType = {}));
/** Page status */
export var PageStatus;
(function (PageStatus) {
    PageStatus["Draft"] = "Draft";
    PageStatus["Public"] = "Public";
})(PageStatus || (PageStatus = {}));
/** Supported Page Media Mime(s) */
export var PageSupportedMediaMime;
(function (PageSupportedMediaMime) {
    PageSupportedMediaMime["GIF"] = "GIF";
    PageSupportedMediaMime["JPEG"] = "JPEG";
    PageSupportedMediaMime["MP3"] = "MP3";
    PageSupportedMediaMime["MP4"] = "MP4";
    PageSupportedMediaMime["PNG"] = "PNG";
    PageSupportedMediaMime["WAV"] = "WAV";
    PageSupportedMediaMime["WEBM"] = "WEBM";
})(PageSupportedMediaMime || (PageSupportedMediaMime = {}));
/** Page Supported Media Types. */
export var PageSupportedMediaType;
(function (PageSupportedMediaType) {
    PageSupportedMediaType["AUDIO"] = "AUDIO";
    PageSupportedMediaType["IMAGE"] = "IMAGE";
    PageSupportedMediaType["VIDEO"] = "VIDEO";
})(PageSupportedMediaType || (PageSupportedMediaType = {}));
/** Section Types */
export var SectionType;
(function (SectionType) {
    SectionType["EditorData"] = "EditorData";
    SectionType["MintButton"] = "MintButton";
})(SectionType || (SectionType = {}));
/** Supported Media Mime(s) */
export var SupportedMediaMime;
(function (SupportedMediaMime) {
    SupportedMediaMime["GIF"] = "GIF";
    SupportedMediaMime["JPEG"] = "JPEG";
    SupportedMediaMime["MP3"] = "MP3";
    SupportedMediaMime["MP4"] = "MP4";
    SupportedMediaMime["PNG"] = "PNG";
    SupportedMediaMime["WAV"] = "WAV";
    SupportedMediaMime["WEBM"] = "WEBM";
})(SupportedMediaMime || (SupportedMediaMime = {}));
/** Supported Media Types. */
export var SupportedMediaType;
(function (SupportedMediaType) {
    SupportedMediaType["AUDIO"] = "AUDIO";
    SupportedMediaType["IMAGE"] = "IMAGE";
    SupportedMediaType["VIDEO"] = "VIDEO";
})(SupportedMediaType || (SupportedMediaType = {}));
/** Contract type */
export var SystemContractType;
(function (SystemContractType) {
    SystemContractType["CollectionFactory"] = "CollectionFactory";
    SystemContractType["ConsensualNonTransferableTokenManager"] = "ConsensualNonTransferableTokenManager";
    SystemContractType["EditionsMetadataRenderer"] = "EditionsMetadataRenderer";
    SystemContractType["MintManager"] = "MintManager";
    SystemContractType["NonTransferableTokenManager"] = "NonTransferableTokenManager";
    SystemContractType["PermissionsRegistry"] = "PermissionsRegistry";
})(SystemContractType || (SystemContractType = {}));
/** Token Notification Type */
export var TokenNotificationType;
(function (TokenNotificationType) {
    TokenNotificationType["MINT"] = "MINT";
    TokenNotificationType["TRANSFER"] = "TRANSFER";
})(TokenNotificationType || (TokenNotificationType = {}));
/** Availability status of the Token. */
export var TokenStatus;
(function (TokenStatus) {
    TokenStatus["Minted"] = "Minted";
    TokenStatus["Pending"] = "Pending";
})(TokenStatus || (TokenStatus = {}));
/** Token type */
export var TokenType;
(function (TokenType) {
    TokenType["All"] = "All";
    TokenType["Benefit"] = "Benefit";
    TokenType["Collectible"] = "Collectible";
    TokenType["Membership"] = "Membership";
})(TokenType || (TokenType = {}));
/** what is the status of the onchain transaction */
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["MINED"] = "MINED";
    TransactionStatus["PENDING"] = "PENDING";
    TransactionStatus["REJECTED"] = "REJECTED";
})(TransactionStatus || (TransactionStatus = {}));
/** type of highlight onchain action associated with this transaction */
export var TransactionType;
(function (TransactionType) {
    TransactionType["EVM_721_MINT"] = "EVM_721_MINT";
    TransactionType["EVM_DEPLOY_COLLECTION"] = "EVM_DEPLOY_COLLECTION";
})(TransactionType || (TransactionType = {}));
/** Type of update Action post reactions. */
export var UpdateActionType;
(function (UpdateActionType) {
    UpdateActionType["ADD"] = "ADD";
    UpdateActionType["REMOVE"] = "REMOVE";
})(UpdateActionType || (UpdateActionType = {}));

import chroma from "chroma-js";
import _merge from "lodash/merge";
import { fontFamilyMap, fontFamilyWeightMap } from "../global/fonts";
import { borderTypes, defaultTheme } from "./theme";
export const borderTypeOptions = [
    { value: "Straight", label: "Straight" },
    { value: "Rounded", label: "Rounded" },
    { value: "Pill", label: "Pill" },
];
export const cardBorderTypeOptions = [
    { value: "Straight", label: "Straight" },
    { value: "Rounded", label: "Rounded" },
];
export const textTransformOptions = [
    { value: "none", label: "Default" },
    { value: "uppercase", label: "Uppercase" },
    { value: "lowercase", label: "Lowercase" },
];
export const fontWeightSelectOptions = [
    { value: "100", label: "100" },
    { value: "200", label: "200" },
    { value: "300", label: "300" },
    { value: "400", label: "400" },
    { value: "500", label: "500" },
    { value: "600", label: "600" },
    { value: "700", label: "700" },
];
export const borderWithOptions = [
    { value: `0`, label: "No Border" },
    { value: `1px`, label: "1px" },
    { value: `2px`, label: "2px" },
    { value: `3px`, label: "3px" },
    { value: `4px`, label: "4px" },
];
const isColorLight = (color) => color.get("lab.l") >= 70;
const isColorDark = (color) => color.get("lab.l") < 70;
export const parseBorderType = (borderType, onTextarea = false) => {
    switch (borderType) {
        case "Pill":
            return !onTextarea ? borderTypes.pill : `35px`;
        case "Rounded":
            return borderTypes.rounded;
        case "Straight":
            return borderTypes.straight;
    }
};
/**
 * This is a temporary hack to avoid issues during deployment, once the
 * DB migration is executed we can remove it.
 */
const fallbackForOldFonts = {
    "'Inter', 'Helvetica Neue', sans-serif": "Inter",
    "'Work Sans', sans-serif": "Work+Sans",
    "'Rakkas', serif": "Rakkas",
    "'Canela'": "Canela",
};
export const updateCommunityOnDefaultTheme = (openCommunityTheme) => {
    if (!openCommunityTheme) {
        return defaultTheme;
    }
    const newTheme = {
        ...defaultTheme,
        community: _merge(defaultTheme.community, openCommunityTheme),
    };
    /**
     * Update colors
     */
    // Backgrounds > secondaryBackground
    const primaryBackgroundColor = chroma(newTheme.community.primaryBackground.backgroundColor);
    newTheme.community.secondaryBackground.backgroundColor = isColorLight(primaryBackgroundColor)
        ? primaryBackgroundColor.darken(0.6).hex()
        : primaryBackgroundColor.brighten(0.6).hex();
    // Text > onPrimaryBackgroundSecondaryColor
    newTheme.community.text.onPrimaryBackgroundSecondaryColor = chroma(newTheme.community.text.onPrimaryBackgroundPrimaryColor)
        .alpha(0.6)
        .hex("rgb");
    // TextInput > placeholderTextColor
    newTheme.community.textInput.placeholderTextColor = chroma(newTheme.community.textInput.primaryTextColor)
        .alpha(0.6)
        .hex("rgb");
    // global > onPageBackgroundTint
    newTheme.community.global.onPageBackgroundTint = isColorDark(chroma(newTheme.community.pageBackground.backgroundColor))
        ? "rgba(255, 255, 255, 0.15)"
        : "rgba(0, 0, 0, 0.15)";
    // global > onPrimaryBackgroundTint
    newTheme.community.global.onPrimaryBackgroundTint = isColorDark(chroma(newTheme.community.primaryBackground.backgroundColor))
        ? "rgba(255, 255, 255, 0.15)"
        : "rgba(0, 0, 0, 0.15)";
    /**
     * Update fonts
     */
    const convertFontFamily = (font) => {
        var _a;
        if (fallbackForOldFonts[font]) {
            return font;
        }
        else {
            return (_a = fontFamilyMap[font]) !== null && _a !== void 0 ? _a : `'Inter', sans-serif`;
        }
    };
    newTheme.community.button.textFontFamily = convertFontFamily(newTheme.community.button.textFontFamily);
    newTheme.community.text.fontFamily = convertFontFamily(newTheme.community.text.fontFamily);
    newTheme.community.title.fontFamily = convertFontFamily(newTheme.community.title.fontFamily);
    return newTheme;
};
export const getFontFamilyWeightList = (font) => {
    var _a;
    const validatedFont = (_a = fontFamilyWeightMap[font]) !== null && _a !== void 0 ? _a : fontFamilyWeightMap["Inter"];
    return validatedFont.map((weight) => ({ value: weight, label: weight }));
};
/**
 * This is a temporary hack to avoid issues during deployment, once the
 * DB migration is executed we can remove it.
 */
export const updateOldFontData = (defaultTheme) => {
    var _a, _b, _c;
    const newTheme = {
        ...defaultTheme,
        button: {
            ...defaultTheme.button,
            textFontFamily: (_a = fallbackForOldFonts[defaultTheme.button.textFontFamily]) !== null && _a !== void 0 ? _a : defaultTheme.button.textFontFamily,
        },
        text: {
            ...defaultTheme.text,
            fontFamily: (_b = fallbackForOldFonts[defaultTheme.text.fontFamily]) !== null && _b !== void 0 ? _b : defaultTheme.text.fontFamily,
        },
        title: {
            ...defaultTheme.title,
            fontFamily: (_c = fallbackForOldFonts[defaultTheme.title.fontFamily]) !== null && _c !== void 0 ? _c : defaultTheme.title.fontFamily,
        },
    };
    return newTheme;
};

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND } from "lexical";
import { Bold as BoldIcon } from "../../../../../../assets/icons.generated";
import { IS_APPLE } from "../../../../../../config";
import { textStylesButton } from "../styles";
const BoldToggle = ({ selectedElement, dark = false }) => {
    const [activeEditor] = useLexicalComposerContext();
    const formatBoldSelection = () => {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
    };
    return (_jsx("button", { css: textStylesButton(dark, selectedElement.isBold), onClick: formatBoldSelection, title: IS_APPLE ? "Bold (⌘B)" : "Bold (Ctrl+B)", "aria-label": `Format text as bold. Shortcut: ${IS_APPLE ? "⌘B" : "Ctrl+B"}`, children: _jsx(BoldIcon, {}) }));
};
export default memo(BoldToggle);

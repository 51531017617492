import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { DecoratorBlockNode, } from "@lexical/react/LexicalDecoratorBlockNode";
import Iframely from "../../../Iframely";
import EditorActionsHandler from "./EditorActionsHandler";
import { PublishToPreview } from "./Placeholder";
export class EmbedTagNode extends DecoratorBlockNode {
    static getType() {
        return "embedtag";
    }
    static clone(node) {
        return new EmbedTagNode(node.__url, node.__format, node.__key);
    }
    constructor(url, format, key) {
        super(format, key);
        this.__url = url;
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isEmbedTagNode(n), children: _jsx(Iframely, { url: this.__url }) }));
    }
    isInline() {
        return false;
    }
    static importJSON(serializedNode) {
        const node = new EmbedTagNode(serializedNode.url);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            url: this.__url,
            type: this.getType(),
            version: 1,
        };
    }
}
export class EmbedTagNodePreview extends EmbedTagNode {
    static getType() {
        return EmbedTagNode.getType();
    }
    static clone(node) {
        return new EmbedTagNodePreview(node.__url, node.__format, node.__key);
    }
    decorate() {
        return (_jsx(EditorActionsHandler, { nodeKey: this.getKey(), nodeCheck: (n) => $isEmbedTagNode(n), children: _jsx(PublishToPreview, {}) }));
    }
    static importJSON(serializedNode) {
        const node = new EmbedTagNodePreview(serializedNode.url);
        node.setFormat(serializedNode.format);
        return node;
    }
}
export function $createEmbedTagNode(url, showEmbedNodes) {
    if (showEmbedNodes) {
        return new EmbedTagNode(url);
    }
    return new EmbedTagNodePreview(url);
}
export function $isEmbedTagNode(node) {
    return node instanceof EmbedTagNode;
}

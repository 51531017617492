import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { css } from "@emotion/react";
const containerStyles = (sticky) => (theme) => css `
    position: ${sticky ? "sticky" : "unset"};
    top: 0;
    z-index: ${theme.zLayers.navBar};
  `;
const LayoutBar = ({ children, sticky = true, }) => {
    return _jsx("div", { css: containerStyles(sticky), children: children });
};
export default memo(LayoutBar);

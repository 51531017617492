import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, memo, } from "react";
import _uniqueId from "lodash/uniqueId";
import Text from "../../Typography/Text";
import Title from "../../Typography/Title";
import * as styles from "./styles";
const TextField = forwardRef(({ error, id, label = "", labelType = "text", placeholder = "", description, labelOnBackgroundColor, type = "text", extraCss, ...rest }, ref) => {
    const htmlFor = _uniqueId("field-");
    const renderTitle = () => {
        switch (labelType) {
            case "text":
                return (_jsx(Text, { size: "medium", bold: true, onBackgroundColor: labelOnBackgroundColor, children: label }));
            case "title":
                return (_jsx(Title, { size: "xsmall", onBackgroundColor: labelOnBackgroundColor, children: label }));
        }
    };
    return (_jsxs("div", { className: rest.className, css: [styles.fieldGroup, extraCss], children: [_jsx("label", { htmlFor: id !== null && id !== void 0 ? id : htmlFor, css: styles.label, children: renderTitle() }), description && (_jsx(Text, { size: "small", extraCss: styles.description, innerHTML: true, children: description })), _jsx("input", { ref: ref, type: type, id: id !== null && id !== void 0 ? id : htmlFor, placeholder: placeholder, css: styles.input(error), ...rest }), error && _jsx("p", { css: styles.error, children: error })] }));
});
export default memo(TextField);

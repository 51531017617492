import { css, Theme } from "@emotion/react";

import { NavBarProps } from "./index";

/** Layout */

export const container = (onScroll: boolean) => (theme: Theme) =>
  css`
    transition-property: background, box-shadow;
    transition-duration: 0.6s;
    transition-timing-function: ${theme.animations.easeOutQuart};

    padding: 0px 16px 0 20px;
    margin-bottom: ${theme.verticalSpaces.large[6]};

    display: flex;
    flex-direction: row;
    align-items: center;

    min-height: 64px;

    ${theme.mediaQueries.smallOnly} {
      min-height: 56px;
      padding: 0 12px 0 20px;
      margin-bottom: ${theme.verticalSpaces.large[6]};
    }

    ${onScroll &&
    css`
      box-shadow: 0 1px 12px rgb(0 0 0 / 3%), 0 1px 8px rgb(0 0 0 / 3%),
        0 0 0 0.5px rgb(0 0 0 / 5%);
      background: ${theme.background.colors.primary};
    `}
  `;

const stylesByLayout = {
  default: css`
    justify-content: space-between;
  `,
  action: css`
    justify-content: space-between;
  `,
  list: css`
    justify-content: space-between;
  `,
  page: css`
    flex-direction: column;
    justify-content: space-between;
  `,
  create: (theme: Theme) => css`
    flex-direction: column-reverse;
    align-items: flex-end;

    ${theme.mediaQueries.mediumUp} {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  dashboard: (theme: Theme) => css`
    justify-content: space-between;
    width: 100%;

    ${theme.mediaQueries.mediumUp} {
      align-items: center;
    }
  `,
};

export const containerLayout = (layout: NavBarProps["layout"]) =>
  stylesByLayout[layout];

export const brandWithHeader = (theme: Theme) => css`
  display: flex;
  gap: 12px;
  align-items: center;

  width: 100%;
  ${theme.mediaQueries.mediumUp} {
    width: auto;
  }
`;

export const logoLarge = (theme: Theme) => css`
  ${theme.mediaQueries.smallOnly} {
    display: none;
  }
`;

export const fillBlack = css`
  path {
    fill: black;
  }
`;

export const logoSmall = (theme: Theme) => css`
  ${theme.mediaQueries.mediumUp} {
    display: none;
  }
`;

/** Nav */

export const nav = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;

  > * {
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const pageNavDivider = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.page.text.color};
  opacity: 0.1;
  width: 100%;
`;

export const pageNavLayout = css`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

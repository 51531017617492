import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const OffersResponseFragmentDoc = gql `
  fragment OffersResponse on MarketplaceOffersPaginated {
    edges {
      criteriaKind
      chainId
      collection {
        address
        id
        onchainId
        imageUrl
        name
        status
      }
      contractAddress
      createdAt
      expiredAt
      feeBps
      fees {
        bps
        kind
        recipient
      }
      floorPrice
      id
      kind
      makerAddress
      price {
        amount {
          decimal
          native
          raw
          usd
        }
        amount {
          decimal
          native
          raw
          usd
        }
        currency {
          contract
          decimals
          name
          symbol
        }
      }
      quantityFilled
      quantityRemaining
      reservoirId
      source
      status
      takerAddress
      tokens {
        animationUrl
        id
        imageUrl
        name
        tokenRevealId
      }
      updatedAt
      validFrom
      validUntil
      user {
        id
        displayName
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      totalCount
    }
  }
`;
export const UserListingsDocument = gql `
  query UserListings(
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
  ) {
    getUserListings(
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
      tokenOrCollectionName: $tokenOrCollectionName
    ) {
      edges {
        highestOffer {
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
          }
        }
        offersCount
        criteriaKind
        chainId
        collection {
          address
          id
          onchainId
          imageUrl
          name
          status
        }
        contractAddress
        createdAt
        expiredAt
        feeBps
        fees {
          bps
          kind
          recipient
        }
        floorPrice
        id
        kind
        makerAddress
        price {
          amount {
            decimal
            native
            raw
            usd
          }
          currency {
            contract
            decimals
            name
            symbol
          }
        }
        quantityFilled
        quantityRemaining
        reservoirId
        source
        status
        takerAddress
        token {
          animationUrl
          id
          imageUrl
          name
          tokenRevealId
        }
        updatedAt
        validFrom
        validUntil
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useUserListingsQuery__
 *
 * To run a query within a React component, call `useUserListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListingsQuery({
 *   variables: {
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserListingsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserListingsDocument, options);
}
export function useUserListingsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserListingsDocument, options);
}
export const TokenOffersDocument = gql `
  query TokenOffers(
    $chainId: Float!
    $tokenId: String!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: OrderSortOptions
    $sortDirection: SortDirection
    $aboveFloor: Boolean
    $createdAfter: DateTime
    $isImportedCollection: Boolean
  ) {
    getTokenOffers(
      chainId: $chainId
      tokenId: $tokenId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      aboveFloor: $aboveFloor
      createdAfter: $createdAfter
      isImportedCollection: $isImportedCollection
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useTokenOffersQuery__
 *
 * To run a query within a React component, call `useTokenOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenOffersQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenId: // value for 'tokenId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      aboveFloor: // value for 'aboveFloor'
 *      createdAfter: // value for 'createdAfter'
 *      isImportedCollection: // value for 'isImportedCollection'
 *   },
 * });
 */
export function useTokenOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TokenOffersDocument, options);
}
export function useTokenOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TokenOffersDocument, options);
}
export const UserOffersDocument = gql `
  query UserOffers(
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
  ) {
    getUserOffers(
      tokenOrCollectionName: $tokenOrCollectionName
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useUserOffersQuery__
 *
 * To run a query within a React component, call `useUserOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOffersQuery({
 *   variables: {
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserOffersDocument, options);
}
export function useUserOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserOffersDocument, options);
}
export const OwnerOffersDocument = gql `
  query OwnerOffers(
    $chainId: Float
    $createdAfter: DateTime
    $tokenId: String
    $tokenAddress: String
    $aboveFloor: Boolean
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
  ) {
    getOwnerOffers(
      chainId: $chainId
      createdAfter: $createdAfter
      tokenId: $tokenId
      tokenAddress: $tokenAddress
      aboveFloor: $aboveFloor
      tokenOrCollectionName: $tokenOrCollectionName
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useOwnerOffersQuery__
 *
 * To run a query within a React component, call `useOwnerOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerOffersQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      createdAfter: // value for 'createdAfter'
 *      tokenId: // value for 'tokenId'
 *      tokenAddress: // value for 'tokenAddress'
 *      aboveFloor: // value for 'aboveFloor'
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOwnerOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(OwnerOffersDocument, options);
}
export function useOwnerOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(OwnerOffersDocument, options);
}
export const CollectionTokensDocument = gql `
  query CollectionTokens(
    $chainId: Float!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: TokenSortOptions
    $sortDirection: SortDirection
    $tokenNameOrId: String
    $withListings: Boolean
    $withOffers: Boolean
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
  ) {
    getCollectionTokens(
      chainId: $chainId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      tokenNameOrId: $tokenNameOrId
      withListings: $withListings
      withOffers: $withOffers
      attributes: $attributes
      minted: $minted
    ) {
      edges {
        id
        tokenRevealId
        tokenAddress
        tokenId
        chainId
        onChainId
        name
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        ownerAddress
        imageUrl
        animationUrl
        minted
        collection {
          id
          onchainId
          address
          name
          imageUrl
          status
          type
        }
        floorListing {
          reservoirId
          contractAddress
          tokenId
          chainId
          takerAddress
          source
          criteriaKind
          kind
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          expiredAt
          token {
            id
            tokenRevealId
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
          price {
            currency {
              contract
              decimals
              name
              symbol
            }
            amount {
              raw
              decimal
              usd
              native
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
        }
        topOffer {
          reservoirId
          contractAddress
          chainId
          takerAddress
          source
          criteriaKind
          kind
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          expiredAt
          price {
            amount {
              raw
              decimal
              usd
              native
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
        }
        offersAboveFloorCount
        tokenListing {
          reservoirId
          status
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useCollectionTokensQuery__
 *
 * To run a query within a React component, call `useCollectionTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionTokensQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      withListings: // value for 'withListings'
 *      withOffers: // value for 'withOffers'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *   },
 * });
 */
export function useCollectionTokensQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionTokensDocument, options);
}
export function useCollectionTokensLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionTokensDocument, options);
}
export const CollectionTokensV2Document = gql `
  query CollectionTokensV2(
    $collectionMarketplaceId: String
    $chainId: Float!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: TokenSortOptions
    $sortDirection: SortDirection
    $tokenNameOrId: String
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
    $withListings: Boolean
    $withOffers: Boolean
    $ownerAddress: String
    $editionId: String
    $isImportedCollection: Boolean
  ) {
    getCollectionTokensV2(
      collectionMarketplaceId: $collectionMarketplaceId
      chainId: $chainId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      tokenNameOrId: $tokenNameOrId
      attributes: $attributes
      minted: $minted
      withListings: $withListings
      withOffers: $withOffers
      ownerAddress: $ownerAddress
      editionId: $editionId
      isImportedCollection: $isImportedCollection
    ) {
      edges {
        id
        tokenRevealId
        tokenAddress
        tokenId
        chainId
        onChainId
        name
        ownerAddress
        imageUrl
        animationUrl
        minted
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        collection {
          id
          onchainId
          address
          name
          imageUrl
          status
          type
        }
        topOffer {
          id
          reservoirId
          contractAddress
          chainId
          takerAddress
          source
          criteriaKind
          kind
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          expiredAt
          price {
            amount {
              raw
              decimal
              usd
              native
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
        }
        tokenListing {
          reservoirId
          status
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useCollectionTokensV2Query__
 *
 * To run a query within a React component, call `useCollectionTokensV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCollectionTokensV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionTokensV2Query({
 *   variables: {
 *      collectionMarketplaceId: // value for 'collectionMarketplaceId'
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *      withListings: // value for 'withListings'
 *      withOffers: // value for 'withOffers'
 *      ownerAddress: // value for 'ownerAddress'
 *      editionId: // value for 'editionId'
 *      isImportedCollection: // value for 'isImportedCollection'
 *   },
 * });
 */
export function useCollectionTokensV2Query(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionTokensV2Document, options);
}
export function useCollectionTokensV2LazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionTokensV2Document, options);
}
export const GetTokensStatsDocument = gql `
  query GetTokensStats($onChainIds: [String!]!) {
    getTokensStats(onChainIds: $onChainIds) {
      floorListing {
        id
        reservoirId
        contractAddress
        tokenId
        chainId
        makerAddress
        takerAddress
        source
        sourceUrl
        criteriaKind
        kind
        status
        quantityFilled
        quantityRemaining
        validFrom
        validUntil
        expiredAt
        collection {
          id
          onchainId
          address
          name
          imageUrl
          status
        }
        token {
          id
          tokenRevealId
          name
          owner
          imageUrl
          animationUrl
        }
        price {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        feeBps
        fees {
          bps
          kind
          recipient
        }
        createdAt
        updatedAt
      }
      offersAboveFloorCount
      offersCount
      onChainId
    }
  }
`;
/**
 * __useGetTokensStatsQuery__
 *
 * To run a query within a React component, call `useGetTokensStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensStatsQuery({
 *   variables: {
 *      onChainIds: // value for 'onChainIds'
 *   },
 * });
 */
export function useGetTokensStatsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetTokensStatsDocument, options);
}
export function useGetTokensStatsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetTokensStatsDocument, options);
}
export const GetCollectionRoyaltyInfoDocument = gql `
  query GetCollectionRoyaltyInfo($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      royalty {
        royaltyPercentageString
        royaltyRecipient
      }
    }
  }
`;
/**
 * __useGetCollectionRoyaltyInfoQuery__
 *
 * To run a query within a React component, call `useGetCollectionRoyaltyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionRoyaltyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionRoyaltyInfoQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionRoyaltyInfoQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetCollectionRoyaltyInfoDocument, options);
}
export function useGetCollectionRoyaltyInfoLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetCollectionRoyaltyInfoDocument, options);
}
export const GetCollectorsChoiceTokensDocument = gql `
  query GetCollectorsChoiceTokens(
    $collectionId: String!
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
    $after: String
    $first: Float
    $tokenNameOrId: String
    $sortDirection: SortDirection
    $sortBy: TokenSortableField
  ) {
    getPublicCollection(collectionId: $collectionId) {
      id
      collectorsChoiceTokens(
        attributes: $attributes
        minted: $minted
        after: $after
        first: $first
        tokenNameOrId: $tokenNameOrId
        sortDirection: $sortDirection
        sortBy: $sortBy
      ) {
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
        edges {
          animationUrl
          chainId
          description
          imageUrl
          minted
          name
          id
          ownerAddress
          tokenId
          attributes {
            name
            value
          }
          tokenListing {
            status
            id
            reservoirId
            price {
              amount {
                native
              }
              currency {
                symbol
              }
              netAmount {
                native
              }
            }
          }
          topOffer {
            id
            price {
              amount {
                native
              }
              currency {
                symbol
              }
              netAmount {
                native
              }
            }
            status
            reservoirId
          }
        }
      }
    }
  }
`;
/**
 * __useGetCollectorsChoiceTokensQuery__
 *
 * To run a query within a React component, call `useGetCollectorsChoiceTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorsChoiceTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorsChoiceTokensQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCollectorsChoiceTokensQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetCollectorsChoiceTokensDocument, options);
}
export function useGetCollectorsChoiceTokensLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetCollectorsChoiceTokensDocument, options);
}

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import { ThemeProvider } from "@emotion/react";
import { createEmotionCache, createStyles, MantineProvider, useMantineTheme, } from "@mantine/core";
import ColorSchemeContext, { ColorSchemeMode, UserColorSchemeSettings, } from "./ColorSchemeContext";
import { darkTheme } from "./dark/theme";
import { lightTheme } from "./light/theme";
import { useColorScheme } from "./useColorScheme";
import { defaultTheme } from ".";
const CombinedEmotionTheme = ({ legacyTheme, children }) => {
    // grab theme from context as it includes all defaults now
    const theme = useMantineTheme();
    const combinedTheme = useMemo(() => ({
        ...legacyTheme,
        ...theme,
    }), [theme, legacyTheme]);
    return _jsx(ThemeProvider, { theme: combinedTheme, children: children });
};
export const ComponentsThemeProvider = ({ baseTheme, legacyTheme, children, emotionCache, withGlobalStyles = true, withNormalizeCSS = true, enableDarkMode = false, userColorScheme, updateUserColorScheme, }) => {
    const { colorScheme, userColorSchemeSettings, selectUserColorScheme } = useColorScheme({
        userColorScheme,
        updateUserColorScheme,
    });
    const theme = useMemo(() => {
        const builtinTheme = colorScheme === ColorSchemeMode.DARK && enableDarkMode
            ? darkTheme
            : lightTheme;
        const theme = baseTheme !== null && baseTheme !== void 0 ? baseTheme : builtinTheme;
        if (!withGlobalStyles) {
            return {
                ...theme,
                globalStyles: undefined,
            };
        }
        return theme;
    }, [colorScheme, enableDarkMode, baseTheme, withGlobalStyles]);
    return (_jsx(ColorSchemeContext.Provider, { value: {
            colorScheme: colorScheme,
            userColorSchemeSettings,
            selectUserColorScheme,
        }, children: _jsx(MantineProvider, { theme: theme, withGlobalStyles: withGlobalStyles, withNormalizeCSS: withNormalizeCSS, emotionCache: emotionCache, children: _jsx(CombinedEmotionTheme, { legacyTheme: legacyTheme, children: children }) }) }));
};
// append styles and use different key to avoid conflict with page css
export const embedEmotionCache = createEmotionCache({
    key: "highlight",
    prepend: false,
});
const useStyles = createStyles((theme) => ({
    // use it instead `withNormalizeCSS` that adds global styles
    normalize: {
        fontFamily: theme.fontFamily,
    },
}));
const EmbedNormalize = ({ children }) => {
    const { classes } = useStyles();
    return _jsx("div", { className: classes.normalize, children: children });
};
export const EmbedThemeProvider = ({ children, customTheme, enableDarkMode }) => (_jsx(ComponentsThemeProvider, { legacyTheme: defaultTheme, emotionCache: embedEmotionCache, withGlobalStyles: enableDarkMode ? true : false, withNormalizeCSS: enableDarkMode ? true : false, baseTheme: customTheme, enableDarkMode: enableDarkMode, userColorScheme: enableDarkMode ? UserColorSchemeSettings.DARK : undefined, children: _jsx(EmbedNormalize, { children: children }) }));

import { ComponentProps, memo, useEffect, useState } from "react";

import { css, Global } from "@emotion/react";
import ErrorBanner from "@hl/communities-app/lib/components/ContentFeed/TextEditor/ErrorBanner";
import { AudioNode } from "@hl/communities-app/lib/components/ContentFeed/TextEditor/nodes/AudioNode";
import {
  EmbedTagNode,
  EmbedTagNodePreview,
} from "@hl/communities-app/lib/components/ContentFeed/TextEditor/nodes/EmbedTagNode";
import {
  DisabledHTMLTagNode,
  HTMLTagNode,
  HTMLTagNodePreview,
} from "@hl/communities-app/lib/components/ContentFeed/TextEditor/nodes/HTMLTagNode";
import { ImageNode } from "@hl/communities-app/lib/components/ContentFeed/TextEditor/nodes/ImageNode";
import { VideoNode } from "@hl/communities-app/lib/components/ContentFeed/TextEditor/nodes/VideoNode";
import AudioAttachmentPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/AudioAttachmentPlugin";
import AutoFocusPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/AutoFocusPlugin";
import AutoLinkPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/AutoLinkPlugin";
import EmbedTagAttachmentPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/EmbedTagAttachmentPlugin";
import EmojiPickerPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/EmojiPickerPlugin";
import HTMLTagAttachmentPlugin, {
  EditHTMLTagHandler,
} from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/HTMLTagAttachmentPlugin";
import ImageAttachmentPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/ImageAttachmentPlugin";
import VideoAttachmentPlugin from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/VideoAttachmentPlugin";
import Text from "@hl/communities-app/lib/components/Typography/Text";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import {
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode } from "@lexical/rich-text";
import {
  EditorState,
  LexicalEditor,
  NodeKey,
  SerializedEditorState,
} from "lexical";

import {
  ModalType,
  resetModalVar,
  updateModalVar,
} from "~/features/layout/Modal/modal";

import EmbedTagSelectModal from "./components/EmbedTagSelectModal";
import HTMLTagSelectModal, {
  ModalPostEditorHTMLTagSelectData,
} from "./components/HTMLTagSelectModal";
import useEditorLogic from "./logic";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import * as styles from "./styles";
import { editorTheme, editorThemeClasses } from "./theme";

const showHTMLTagSelectorModal = (
  html: string,
  nodeKey: NodeKey,
  editHTMLTag: EditHTMLTagHandler
) => {
  updateModalVar<ModalPostEditorHTMLTagSelectData>({
    showModal: ModalType.EDITOR_HTML_SELECT,
    data: {
      editHTMLTag,
      html,
      nodeKey,
    },
  });
};

export type EditorComposerProps = {
  initialEditable: boolean;
  readOnlyState?: SerializedEditorState;
  onEditorChange?(editorState: EditorState, editor: LexicalEditor): void;
  onFileSelected?(f: File): void;
  disableHTMLPlugin: boolean;
  showEmbedNodes: boolean;
};

const Placeholder = () => {
  return (
    <Text as="span" color="secondary" extraCss={styles.editorPlaceholder}>
      Add content to your page...
    </Text>
  );
};

const EditorComposer = ({
  initialEditable,
  readOnlyState,
  onEditorChange,
  onFileSelected,
  disableHTMLPlugin,
  showEmbedNodes,
}: EditorComposerProps) => {
  const {
    editable,
    setEditable,
    handleFileSelected,
    blockType,
    selectedElement,
    error,
    setError,
  } = useEditorLogic({
    initialEditable,
    onFileSelected,
  });
  const [editor] = useLexicalComposerContext();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLDivElement | undefined
  >(undefined);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    if (readOnlyState) {
      const editorState = editor.parseEditorState(readOnlyState);
      editor.setEditorState(editorState);
    }
  }, [editor, readOnlyState]);

  useEffect(() => {
    setEditable(editable);
  }, [editable]);

  return (
    <>
      <div css={editable ? styles.editorContainer : null}>
        {editable && (
          <ToolbarPlugin
            blockType={blockType}
            isEditable={editable}
            selectedElement={selectedElement}
            handleFileSelected={handleFileSelected}
            anchorElem={floatingAnchorElem}
            disableHTMLPlugin={disableHTMLPlugin}
          />
        )}

        <div css={styles.editorContent}>
          <RichTextPlugin
            contentEditable={
              <div
                css={css`
                  min-height: inherit;
                `}
                ref={onRef}
              >
                <ContentEditable css={styles.editorInput} />
              </div>
            }
            placeholder={editable ? <Placeholder /> : ""}
          />

          {/* Content */}
          <AutoLinkPlugin />
          <LinkPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ClearEditorPlugin />
          <ListPlugin />
          <EmojiPickerPlugin />

          {/* Behavior */}
          {onEditorChange && (
            <OnChangePlugin onChange={onEditorChange} ignoreSelectionChange />
          )}

          {/* Attachments */}
          <AudioAttachmentPlugin />
          <VideoAttachmentPlugin />
          <ImageAttachmentPlugin />
          <EmbedTagAttachmentPlugin showEmbedNodes={showEmbedNodes} />
          <HTMLTagAttachmentPlugin
            onEditClickHandler={showHTMLTagSelectorModal}
            postInsertHandler={resetModalVar}
            showEmbedNodes={showEmbedNodes}
          />
        </div>

        {editable && (
          <>
            <ErrorBanner
              error={error}
              setError={setError}
              isEditable={editable}
            />
          </>
        )}
      </div>
      {/*{editable && <TreeViewPlugin />}*/}
    </>
  );
};

/**
 * Main Component
 */
export type EditorProps = {
  editable?: EditorComposerProps["initialEditable"];
  readOnlyState?: EditorComposerProps["readOnlyState"];
  initialEditorState?: InitialEditorStateType;
  showEmbedNodes?: boolean;
  onEditorChange?: EditorComposerProps["onEditorChange"];
  onFileSelected?: EditorComposerProps["onFileSelected"];
};

type LexicalComposerInitialConfig = ComponentProps<
  typeof LexicalComposer
>["initialConfig"];

const Editor = ({
  editable = false,
  readOnlyState,
  showEmbedNodes = true,
  initialEditorState,
  onEditorChange,
  onFileSelected,
}: EditorProps) => {
  const disableHTMLPlugin = useFeatureFlags(
    FEATURE_FLAGS.EDITOR_DISABLE_HTML_PLUGIN
  );

  const initialConfig: LexicalComposerInitialConfig = {
    namespace: "PageEditor",
    theme: editorThemeClasses,
    editorState: initialEditorState,
    onError(error: unknown) {
      console.error(error);
    },
    nodes: [
      HeadingNode,
      AutoLinkNode,
      ListNode,
      ListItemNode,
      LinkNode,
      AudioNode,
      VideoNode,
      ImageNode,
      showEmbedNodes ? EmbedTagNode : EmbedTagNodePreview,
      ...(disableHTMLPlugin
        ? [DisabledHTMLTagNode]
        : [showEmbedNodes ? HTMLTagNode : HTMLTagNodePreview]),
    ],
    editable,
  };

  return (
    <>
      <Global styles={editorTheme} />
      <LexicalComposer initialConfig={initialConfig}>
        <EditorComposer
          initialEditable={editable}
          readOnlyState={readOnlyState}
          onEditorChange={onEditorChange}
          onFileSelected={onFileSelected}
          disableHTMLPlugin={disableHTMLPlugin ?? false}
          showEmbedNodes={showEmbedNodes}
        />
      </LexicalComposer>
      {editable && <EmbedTagSelectModal />}
      {editable && <HTMLTagSelectModal />}
    </>
  );
};

export default memo(Editor);

import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { $getNodeByKey, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { $createHTMLTagNode, $isHTMLTagNode, HTMLTagNode, } from "../nodes/HTMLTagNode";
export const EDIT_HTML_EMBED_EVENT = "editor:editHTMLEmbed";
export const INSERT_HTML_TAG_COMMAND = createCommand();
export default function HTMLTagAttachmentPlugin({ onEditClickHandler, postInsertHandler, showEmbedNodes, }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([HTMLTagNode])) {
            throw new Error("HTMLTagPlugin: HTMLTagNode not registered on editor");
        }
        return editor.registerCommand(INSERT_HTML_TAG_COMMAND, (payload) => {
            const htmlTagNode = $createHTMLTagNode(payload.html, !!(payload === null || payload === void 0 ? void 0 : payload.disabled), showEmbedNodes);
            $insertNodeToNearestRoot(htmlTagNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor, showEmbedNodes]);
    useEffect(() => {
        document.addEventListener(EDIT_HTML_EMBED_EVENT, onEditHtmlHandler);
        return () => {
            document.removeEventListener(EDIT_HTML_EMBED_EVENT, onEditHtmlHandler);
        };
    }, []);
    const editHTMLTag = (html, nodeKey) => {
        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isHTMLTagNode(node)) {
                node.setData(html);
            }
        });
        postInsertHandler();
    };
    const onEditHtmlHandler = (event) => {
        const typedEvent = event;
        onEditClickHandler(typedEvent.detail.html, typedEvent.detail.nodeKey, editHTMLTag);
    };
    return null;
}

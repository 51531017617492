import { css } from "@emotion/react";
import { baseBackgroundTextColor, baseTextColor, } from "../../../styles/global/typography";
export const base = (theme) => css `
    font-family: ${theme.title.fontFamily};
    font-weight: ${theme.title.fontWeight};
    text-transform: ${theme.title.textTransform};
    font-feature-settings: "ss02" on; // Single story "g"
  `;
export const bySize = (size) => (theme) => css `
      font-size: ${theme.title.sizes[size]};
      line-height: ${theme.title.lineHeights[size]};
      letter-spacing: ${theme.title.letterSpacing[size]};

      ${theme.mediaQueries.smallOnly} {
        font-size: ${theme.title.sizes.smallScreen[size]};
        line-height: ${theme.title.lineHeights.smallScreen[size]};
      }
    `;
export const byColor = (color, inverted) => baseTextColor(color, inverted);
export const byBackgroundTextColor = (color) => baseBackgroundTextColor(color);

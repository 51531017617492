const getRequiredEnvVar = (name: string) => {
  const value = process.env[name];
  if (!value) {
    console.error(`env var ${name} is not set`);
  }
  return value as string;
};

export const envConfig = {
  graphql: {
    httpUrl: getRequiredEnvVar("REACT_APP_WEB_GRAPHQL_URL"),
  },
  isDevelopment: process.env.REACT_APP_ENV !== "production",
};

export const mintConfig = {
  mintFunction: getRequiredEnvVar("REACT_APP_MINT_FUNCTION"),
};

/* URLS */

export const GATES_URL = Object.freeze({
  home: "/",
});

const { protocol, host } = window.location;
const TOOLS_DOMAIN = host.includes("localhost")
  ? host.replace(new RegExp("[0-9]+"), "3002")
  : host.replace("gate", "tools");
export const TOOLS_BASE_URL = `${protocol}//${TOOLS_DOMAIN}`;

export const TOOLS_URL = Object.freeze({
  home: `${TOOLS_BASE_URL}`,
  pages: {
    list: `${TOOLS_BASE_URL}/pages`,
  },
});

export const EXTERNAL_URL = Object.freeze({
  SUPPORT: "https://support.highlight.xyz",
  OPENSEA: "https://opensea.io/assets",
  OPENSEA_TESTNET: "https://testnets.opensea.io/assets",
  POLYGONSCAN: "https://polygonscan.com",
  MUMBAISCAN: "https://mumbai.polygonscan.com",
  ETHERSCAN: "https://etherscan.io",
  GOERLISCAN: "https://goerli.etherscan.io",
  SEPOLIASCAN: "https://sepolia.etherscan.io",
  ARBISCAN: "https://arbiscan.io/",
  ARBIGOERLISCAN: "https://goerli.arbiscan.io/",
  OPTIMISMSCAN: "https://optimistic.etherscan.io/",
  OPTIMISTICGOERLISCAN: "https://goerli-optimism.etherscan.io/",
  BASEGOERLISCAN: "https://goerli.basescan.org/",
  BASESEPOLIASCAN: "https://sepolia.basescan.org/",
  HIGHLIGHT: "https://highlight.xyz",
});

/* STORAGE KEYS */
export const STORAGE_KEYS = Object.freeze({
  auth: "highlight.auth",
  analyticsDevice: "highlight.analytics.device",
});

/* SEO Configs */
export const HIGHLIGHT_PAGE_TITLE_SIGNATURE = " | Highlight";

export const JWT_EXPIRATION_DAYS = 10; // in days
export const IN_DEVELOPMENT = process.env.REACT_APP_ENV !== "production";
export const GOOGLE_ANALYTICS_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "";

export const DEFAULT_ERROR_MESSAGE =
  "A server error occurred, please try again. If this error persists, contact us at support@highlight.xyz with details.";

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useCallback, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNodeByKey, $getSelection, $isNodeSelection, COMMAND_PRIORITY_LOW, KEY_DELETE_COMMAND, } from "lexical";
import { CrossSmall as CloseIcon } from "../../../../assets/icons.generated";
import AttachmentFloatingButton from "./AttachmentFloatingButton";
const AttachmentRemoveButton = ({ isSelected, setSelected, nodeKey, nodeCheck, }) => {
    const [editor] = useLexicalComposerContext();
    const onDelete = useCallback((payload) => {
        if (isSelected && $isNodeSelection($getSelection())) {
            payload.preventDefault();
            const node = $getNodeByKey(nodeKey);
            if (nodeCheck(node) && node) {
                node.remove();
            }
            setSelected(false);
        }
        return false;
    }, [isSelected, nodeKey]);
    useEffect(() => {
        return editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW);
    }, [editor, onDelete]);
    const handleRemove = () => {
        editor.dispatchCommand(KEY_DELETE_COMMAND, new KeyboardEvent("keydown", {
            key: "Escape",
            code: "Escape",
        }));
    };
    if (!editor.isEditable())
        return null;
    return (_jsx(AttachmentFloatingButton, { icon: _jsx(CloseIcon, {}), color: "destructive", onClick: handleRemove, children: "Remove" }));
};
export default memo(AttachmentRemoveButton);

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useRef } from "react";
import { css } from "@emotion/react";
/**
 * Styles
 */
const styles = {
    nodeWrapper: css `
    position: relative;
  `,
    image: css `
    max-width: 100%;
  `,
};
/**
 * Helpers
 */
const isMediaMetadata = (toBeDefined) => {
    if (!toBeDefined)
        return false;
    return toBeDefined.mime !== undefined;
};
const ImageAttachment = ({ attachment }) => {
    const ref = useRef(null);
    if (!isMediaMetadata(attachment) || !attachment.url) {
        return null;
    }
    return (_jsx("div", { css: styles.nodeWrapper, ref: ref, children: _jsx("img", { css: styles.image, src: attachment === null || attachment === void 0 ? void 0 : attachment.url, alt: undefined }) }));
};
export default ImageAttachment;

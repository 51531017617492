import { FC, memo, ReactNode, useCallback } from "react";

import { CloseXSm } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { Button, Divider, Text, UnstyledButton } from "@mantine/core";
import ReactModal from "react-modal";

import { resetModalVar } from "./modal";
import * as styles from "./styles";

type Props = {
  canClose?: boolean;
  onClose?: () => void;
  title?: ReactNode;
  closeLabel?: string;
} & ReactModal.Props;

const ModalBase: FC<Props> = ({
  children,
  canClose = true,
  onClose,
  title,
  closeLabel,
  closeTimeoutMS = 800,
  ariaHideApp = false,
  ...rest
}) => {
  const { classes } = styles.useStyles();

  const handleClose = useCallback(() => {
    resetModalVar();
    if (onClose) onClose();
  }, [onClose]);

  return (
    <ReactModal
      onRequestClose={canClose ? handleClose : undefined}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={ariaHideApp}
      className={{
        base: classes.base,
        afterOpen: classes.afterOpen,
        beforeClose: classes.beforeCLose,
      }}
      overlayClassName={{
        base: classes.overlayBase,
        afterOpen: classes.overlayAfterOpen,
        beforeClose: classes.overlayBeforeClose,
      }}
      {...rest}
    >
      <div className={classes.header}>
        {typeof title === "function" ? (
          title
        ) : (
          <Text size="lg" weight={500}>
            {title}
          </Text>
        )}
        {canClose && (
          <>
            {closeLabel ? (
              <Button color={SECONDARY_COLOR} size="sm" onClick={handleClose}>
                <Text>{closeLabel}</Text>
              </Button>
            ) : (
              <UnstyledButton
                className={classes.closeIconContainer}
                onClick={handleClose}
              >
                <CloseXSm />
              </UnstyledButton>
            )}
          </>
        )}
      </div>
      <Divider mt={12} mx={-16} />
      <div className={classes.body}>{children}</div>
    </ReactModal>
  );
};

export default memo(ModalBase);

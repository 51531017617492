import { memo, useCallback, useEffect, useState, FC, ReactNode } from "react";

import {
  BrandHighlightLight as HighlightBrandLight,
  BrandHighlightSmallLight as HighlightBrandSmallLight,
  BrandHighlightSmall as HighlightBrandSmall,
  BrandHighlightNoLogo as HighlightBrandNoLogo,
} from "@hl/communities-app/lib/assets/icons.generated";
import { UserNavbar } from "@hl/shared-features/lib/features/auth/UserNavbar";
import { Group } from "@mantine/core";
import { Link } from "react-router-dom";

import { ReactComponent as HighlightBrandLogoOnly } from "../../../assets/icons/brand-just-highlight-logo.svg";

import * as styles from "./styles";

const GateNav = ({
  leftButtons,
  extraButtons,
}: {
  leftButtons?: ReactNode;
  extraButtons?: ReactNode;
  usePageTheme?: boolean;
}) => {
  return (
    <nav css={styles.nav} role="navigation" aria-label="Main">
      <Group position="apart" grow sx={{ flexGrow: 1 }}>
        {leftButtons && <div>{leftButtons}</div>}
        <Group position="right">
          <UserNavbar />
          {extraButtons}
        </Group>
      </Group>
    </nav>
  );
};

type BrandProps = {
  redirectHome?: boolean;
  lightContent?: boolean;
};
const Brand: FC<BrandProps> = ({ redirectHome, lightContent }) =>
  redirectHome ? (
    <div>
      <Link to="/">
        {lightContent ? (
          <>
            <HighlightBrandLight css={styles.logoLarge} />
            <HighlightBrandSmallLight css={styles.logoSmall} />
          </>
        ) : (
          <>
            <HighlightBrandNoLogo css={styles.logoLarge} />
            <HighlightBrandSmall css={styles.logoSmall} />
          </>
        )}
      </Link>
    </div>
  ) : (
    <div>
      {lightContent ? (
        <>
          <HighlightBrandLight css={styles.logoLarge} />
          <HighlightBrandSmallLight css={styles.logoSmall} />
        </>
      ) : (
        <>
          <HighlightBrandNoLogo css={styles.logoLarge} />
          <HighlightBrandSmall css={styles.logoSmall} />
        </>
      )}
    </div>
  );

export type LayoutType =
  | "default"
  | "page"
  | "list"
  | "create"
  | "action"
  | "dashboard";

export type NavBarProps = {
  layout: LayoutType;
  header?: ReactNode;
  leftButtons?: ReactNode;
  extraButtons?: ReactNode;
  sticky?: boolean;
  logoLinkTo?: string;
  showBrand?: boolean;
};

/** Main Component */

const NavBar: FC<NavBarProps> = ({
  layout,
  header,
  extraButtons,
  leftButtons,
  sticky,
  logoLinkTo,
  showBrand = true,
}) => {
  const [onScroll, setOnScroll] = useState<boolean>(false);

  const handleNavigation = useCallback(
    (e: Event) => {
      const window = e.currentTarget as Window;
      if (window.scrollY > 0 && !onScroll) {
        setOnScroll(true);
      } else if (window.scrollY <= 0 && onScroll) {
        setOnScroll(false);
      }
    },
    [onScroll]
  );

  useEffect(() => {
    if (!sticky) return;

    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation, sticky]);

  const Content = ({ linkTo = "/" }: { linkTo?: string }) => {
    switch (layout) {
      case "page":
        return (
          <>
            <div css={styles.pageNavLayout}>
              <div>{header}</div>
              <GateNav
                leftButtons={leftButtons}
                extraButtons={extraButtons}
                usePageTheme
              />
            </div>
            <div css={styles.pageNavDivider} />
          </>
        );
      case "list":
        return (
          <>
            {showBrand && (
              <div>
                <Link to={linkTo}>
                  <HighlightBrandLogoOnly />
                </Link>
              </div>
            )}

            <GateNav leftButtons={leftButtons} extraButtons={extraButtons} />
          </>
        );
      case "create":
        return (
          <>
            {(showBrand || header) && (
              <div css={styles.brandWithHeader}>
                <div>
                  <Link to={linkTo}>
                    <HighlightBrandLogoOnly css={styles.logoLarge} />
                  </Link>
                </div>
                {header}
              </div>
            )}
            <GateNav leftButtons={leftButtons} extraButtons={extraButtons} />
          </>
        );
      case "dashboard":
        return (
          <>
            {(showBrand || header) && (
              <div css={styles.brandWithHeader}>
                <div>
                  <Link to={linkTo}>
                    <HighlightBrandLight
                      css={[styles.logoLarge, styles.fillBlack]}
                    />
                  </Link>
                </div>
                {header}
              </div>
            )}
            <GateNav leftButtons={leftButtons} extraButtons={extraButtons} />
          </>
        );
      case "action":
        return (
          <>
            <div css={styles.brandWithHeader}>
              <div>{extraButtons}</div>
              {header}
            </div>
          </>
        );
      default:
        return (
          <>
            <Brand redirectHome />
          </>
        );
    }
  };

  return (
    <div css={[styles.container(onScroll), styles.containerLayout(layout)]}>
      <Content linkTo={logoLinkTo} />
    </div>
  );
};

export default memo(NavBar);

import { NetworkType } from "../apollo/graphql.generated";
import { EXTERNAL_URL } from "../config";
import { networkLookup } from "./blockExplorer";
const baseOpenSeaUrl = (network) => {
    if ([
        NetworkType.GOERLI,
        NetworkType.MUMBAI,
        NetworkType.ARBITRUM_GOERLI,
        NetworkType.OPTIMISM_GOERLI,
        NetworkType.BASE_GOERLI,
        NetworkType.ZORA_GOERLI,
        NetworkType.SEPOLIA,
        NetworkType.BASE_SEPOLIA,
    ].includes(network)) {
        return EXTERNAL_URL.OPENSEA_TESTNET;
    }
    return EXTERNAL_URL.OPENSEA;
};
const isOpenSeaSupported = (network) => {
    if ([NetworkType.FORMA, NetworkType.MANTLE, NetworkType.SCROLL].includes(network)) {
        return false;
    }
    return true;
};
const networkPath = (network) => {
    if (network === NetworkType.POLYGON) {
        return "matic";
    }
    // OpenSea uses dashes to separate words. e.g. "base-goerli"
    return network === null || network === void 0 ? void 0 : network.toLowerCase().replace("_", "-");
};
const buildOpenSeaTokenUrl = (contractAddress, tokenId, network) => `${baseOpenSeaUrl(network)}/${networkPath(network)}/${contractAddress}/${tokenId}`;
const buildOpenSeaCollectionUrl = (contractAddress, network) => `${baseOpenSeaUrl(network)}/${networkPath(network)}/${contractAddress}`;
const buildOpenSeaLinkUrl = (contractAddress, network, tokenId) => {
    const blockchainNetwork = NetworkType[network.toUpperCase()];
    if (tokenId) {
        return buildOpenSeaTokenUrl(contractAddress, tokenId, blockchainNetwork);
    }
    return buildOpenSeaCollectionUrl(contractAddress, blockchainNetwork);
};
export const chainIdNetworkMap = {
    1: NetworkType.ETHEREUM,
    5: NetworkType.GOERLI,
    11155111: NetworkType.SEPOLIA,
    137: NetworkType.POLYGON,
    80001: NetworkType.MUMBAI,
    42161: NetworkType.ARBITRUM,
    421613: NetworkType.ARBITRUM_GOERLI,
    10: NetworkType.OPTIMISM,
    420: NetworkType.OPTIMISM_GOERLI,
    8453: NetworkType.BASE,
    84531: NetworkType.BASE_GOERLI,
    84532: NetworkType.BASE_SEPOLIA,
    7777777: NetworkType.ZORA,
    999: NetworkType.ZORA_GOERLI,
    984122: NetworkType.FORMA,
    5000: NetworkType.MANTLE,
    534352: NetworkType.SCROLL,
};
export const getNetworkTypeForChainId = (chainId) => {
    return chainIdNetworkMap[chainId];
};
export const buildOpenSeaLinkUrl2 = (address, chainId, tokenId) => {
    const networkType = getNetworkTypeForChainId(chainId);
    if (!networkType) {
        return "";
    }
    return buildOpenSeaLinkUrl(address, networkType, tokenId);
};
const openSeaRegex = /^https:\/\/opensea\.io\/collection\/[a-zA-Z0-9_.-]*/;
const validateOpenSeaUrl = (url) => {
    const matches = url.trim().match(openSeaRegex);
    return !!matches;
};
const getOpenSeaCollectionSlugFromUrl = (url) => {
    const matches = url.trim().match(openSeaRegex);
    if (matches) {
        const url = matches[0];
        const splits = url.split("/");
        return splits[splits.length - 1];
    }
    return "";
};
const viewCollectionOpenSeaUrl = (contractAddress, chainId) => {
    var _a;
    const network = (_a = networkLookup(chainId)) === null || _a === void 0 ? void 0 : _a.type;
    return `${baseOpenSeaUrl(network)}/${networkPath(network)}/${contractAddress}`;
};
export { buildOpenSeaLinkUrl, buildOpenSeaTokenUrl, validateOpenSeaUrl, getOpenSeaCollectionSlugFromUrl, viewCollectionOpenSeaUrl, isOpenSeaSupported, };

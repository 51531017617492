import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CodeBrowser as EmbedIcon } from "../../../../../../assets/icons.generated";
import { INSERT_EMBED_TAG_COMMAND } from "../../EmbedTagAttachmentPlugin";
import BaseAttachment from "../BaseAttachment";
const INSERT_EMBED_EVENT = "editor:insertEmbed";
/**
 * A Custom Event is used instead of directly calling the function in the modal
 * because it can lead to issues like losing Lexical context, that end up adding
 * the embed in the wrong places (like the end of the editor instead of the
 * cursor position.
 */
export const dispatchEmbedInsert = (url) => document.dispatchEvent(new CustomEvent(INSERT_EMBED_EVENT, {
    detail: { url },
}));
const EmbedAttachment = ({ onEmbedButtonClickHandler, postInsertHandler, }) => {
    const [activeEditor] = useLexicalComposerContext();
    useEffect(() => {
        document.addEventListener(INSERT_EMBED_EVENT, embedListener);
        return () => {
            document.removeEventListener(INSERT_EMBED_EVENT, embedListener);
        };
    }, []);
    const embedListener = (event) => {
        const typedEvent = event;
        insertEmbedTag(typedEvent.detail.url);
    };
    const insertEmbedTag = (url) => {
        activeEditor.focus();
        activeEditor.dispatchCommand(INSERT_EMBED_TAG_COMMAND, { url });
        postInsertHandler();
    };
    return (_jsx(BaseAttachment, { title: "Embed", children: _jsx("button", { onClick: onEmbedButtonClickHandler, children: _jsx(EmbedIcon, {}) }) }));
};
export default memo(EmbedAttachment);

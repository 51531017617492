import { $isAtNodeEnd } from "@lexical/selection";
import { IS_APPLE } from "../../../config";
const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;
export function setFloatingElemPosition(targetRect, floatingElem, anchorElem, verticalGap = VERTICAL_GAP, horizontalOffset = HORIZONTAL_OFFSET) {
    if (targetRect === null) {
        floatingElem.style.opacity = "0";
        floatingElem.style.top = "-10000px";
        floatingElem.style.left = "-10000px";
        return;
    }
    const floatingElemRect = floatingElem.getBoundingClientRect();
    const anchorElementRect = anchorElem.getBoundingClientRect();
    let top = targetRect.top - floatingElemRect.height - verticalGap;
    let left = targetRect.left - horizontalOffset;
    if (top < anchorElementRect.top) {
        top += floatingElemRect.height + targetRect.height + verticalGap * 2;
    }
    if (left + floatingElemRect.width > anchorElementRect.right) {
        left = anchorElementRect.right - floatingElemRect.width - horizontalOffset;
    }
    top -= anchorElementRect.top;
    left -= anchorElementRect.left;
    floatingElem.style.opacity = "1";
    floatingElem.style.top = `${top}px`;
    floatingElem.style.left = `${left}px`;
}
export function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}
export function controlOrMeta(metaKey, ctrlKey) {
    if (IS_APPLE) {
        return metaKey;
    }
    return ctrlKey;
}

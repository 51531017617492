import { memo } from "react";

import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { Stack, Text, Anchor, Center, Tooltip } from "@mantine/core";

import { ReactComponent as HighlightBrandLogoOnly } from "../../../assets/icons/brand-just-highlight-logo.svg";
import PageText from "../../../components/Page/Text";
import { TOOLS_URL } from "../../../config";

import * as styles from "./styles";
const Footer = ({
  showTooltip = false,
  usePageTheme = false,
}: {
  showTooltip?: boolean;
  usePageTheme?: boolean;
}) => {
  return (
    <footer>
      <div css={styles.container}>
        {showTooltip ? (
          <Tooltip
            p="sm"
            withArrow
            closeDelay={2000}
            sx={{
              pointerEvents: "all",
            }}
            label={
              <Stack align="center" spacing={4}>
                <Text
                  size="xs"
                  color={TEXT_COLOR.INVERTED_PRIMARY}
                  weight={500}
                >
                  Powered by Highlight
                </Text>
                <Anchor
                  size="xs"
                  href={TOOLS_URL.pages.list}
                  target="_blank"
                  underline={false}
                >
                  <Text
                    size="xs"
                    color={TEXT_COLOR.INVERTED_PRIMARY}
                    weight={400}
                  >
                    Create a page
                  </Text>
                </Anchor>
              </Stack>
            }
          >
            <Center m={0} p={0}>
              <HighlightBrandLogoOnly css={styles.logo(usePageTheme)} />
              <PageText size="xsmall" as="span">
                Powered by Highlight
              </PageText>
            </Center>
          </Tooltip>
        ) : (
          <Center>
            <HighlightBrandLogoOnly css={styles.logo(usePageTheme)} />
            <Text size="xs">Powered by Highlight</Text>
          </Center>
        )}
      </div>
    </footer>
  );
};

export default memo(Footer);

import { FC, memo, PropsWithChildren } from "react";

import { css, Theme } from "@emotion/react";
import { SystemTextProps } from "@hl/communities-app/lib/components/Typography/Text";

import { Text } from "../Typography";

export const pageThemeText = (theme: Theme) => css`
  font-family: ${theme.page.text.fontFamily};
  color: ${theme.page.text.color};
`;

const PageText: FC<PropsWithChildren<SystemTextProps>> = ({
  additionalCss,
  extraCss,
  children,
  ...rest
}) => {
  return (
    <Text extraCss={[pageThemeText, additionalCss, extraCss]} {...rest}>
      {children}
    </Text>
  );
};

export default memo(PageText);

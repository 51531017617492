import {
  SupportedMediaMime,
  SupportedMediaType,
} from "@hl/communities-app/lib/apollo/graphql.generated";

export const mimeFromType: Record<string, string> = {
  "audio/mpeg": SupportedMediaMime.MP3,
  "audio/wav": SupportedMediaMime.WAV,
  "image/png": SupportedMediaMime.PNG,
  "image/gif": SupportedMediaMime.GIF,
  "image/jpeg": SupportedMediaMime.JPEG,
  "image/jpg": SupportedMediaMime.JPEG,
  "video/webm": SupportedMediaMime.WEBM,
  "video/mp4": SupportedMediaMime.MP4,
};

export const formatsByType: Record<SupportedMediaType, SupportedMediaMime[]> = {
  [SupportedMediaType.IMAGE]: [
    SupportedMediaMime.JPEG,
    SupportedMediaMime.PNG,
    SupportedMediaMime.GIF,
  ],
  [SupportedMediaType.AUDIO]: [SupportedMediaMime.MP3, SupportedMediaMime.WAV],
  [SupportedMediaType.VIDEO]: [SupportedMediaMime.MP4, SupportedMediaMime.WEBM],
};

export const sizeByType: Record<SupportedMediaType, string> = {
  [SupportedMediaType.IMAGE]: "30MB",
  [SupportedMediaType.AUDIO]: "1GB",
  [SupportedMediaType.VIDEO]: "2GB",
};

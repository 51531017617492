import { IFRAMELY_HASHED_API_KEY, IN_DEVELOPMENT } from "../config";
export const loadIframely = () => {
    var _a;
    if (typeof window === "undefined" || !IFRAMELY_HASHED_API_KEY) {
        IN_DEVELOPMENT &&
            console.log("Iframely couldn't load. Undefined window object or missing API key");
        return;
    }
    // Replace 'iframe.ly' with your custom CDN if available.
    if (document.querySelectorAll("[data-iframely-url]").length === 0 &&
        document.querySelectorAll("iframe[src*='iframe.ly']").length === 0) {
        return;
    }
    const iframely = (window.iframely = window.iframely || {});
    if (iframely.load) {
        IN_DEVELOPMENT && console.log("Loading Iframely script.");
        iframely.load();
    }
    else {
        IN_DEVELOPMENT && console.log("Creating Iframely script.");
        const iframelyScriptEl = document.createElement("script");
        iframelyScriptEl.type = "text/javascript";
        iframelyScriptEl.async = true;
        iframelyScriptEl.src =
            ("https:" == document.location.protocol ? "https:" : "http:") +
                "//cdn.iframe.ly/embed.js";
        iframelyScriptEl.onload = () => {
            IN_DEVELOPMENT && console.log("Iframely loaded successfully.");
        };
        iframelyScriptEl.onerror = () => {
            IN_DEVELOPMENT && console.log("Iframely failed to load.");
        };
        const firstScriptEl = document.getElementsByTagName("script")[0];
        (_a = firstScriptEl.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(iframelyScriptEl, firstScriptEl);
    }
};
/**
 * Our highlight.xyz (and staging) domains are whitelisted to prevent actors from copying and using our IFRAMELY_HASHED_API_KEY for their own purposes,
 * as the key will be publicly visible
 * @param url embed URL
 */
export const fetchEmbed = async (url) => {
    if (!IFRAMELY_HASHED_API_KEY) {
        throw new Error("Missing Iframely API key.");
    }
    return fetch(`https://cdn.iframe.ly/api/oembed?url=${encodeURIComponent(url)}&key=${IFRAMELY_HASHED_API_KEY}&iframe=1&lazy=1&omit_script=1`);
};

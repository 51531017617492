/**
 * Matches root domain for highlight like highlight.xyz and highlightstaging.xyz,
 * and don't match anything else like tools.highlight.xyz
 */
export const inRootDomain = () => /^highlight.*\./.test(new URL(window.location.href).hostname);
/**
 * Get the domain string except for the first subdomain.
 * e.g. tools.highlight.xyz returns highlight.xyz, and highlight.xyz returns itself.
 */
export const getRootDomain = () => {
    var _a;
    return (_a = new URL(window.location.href).hostname.match(/(?:[^./]+\.)?([^./]+\.[^./]+)$/)) === null || _a === void 0 ? void 0 : _a[1];
};
export const getDomain = () => {
    const hostname = new URL(window.location.href).hostname;
    return /\bhighlight.*\./.test(hostname) ? hostname : "";
};

import { memo, useState } from "react";

import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { css } from "@emotion/react";
import { dispatchEmbedInsert } from "@hl/communities-app/lib/components/ContentFeed/TextEditor/plugins/Attachment/EmbedAttachment";
import TextField from "@hl/communities-app/lib/components/Inputs/TextField";
import { fetchEmbed } from "@hl/communities-app/lib/services/iframely";
import { logError } from "@hl/shared-features/lib/services/logger";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mantine/core";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";

import { urlRegex } from "utils/strings";
import ModalBase from "~/features/layout/Modal/ModalBase";

import { Modal, ModalType, modalVar } from "../../../layout/Modal/modal";

const styles = {
  usernameInput: css`
    margin-bottom: 16px;
  `,
  title: css`
    text-align: center;
    margin: 0 0 32px 0;
  `,
};

export type ModalPostEditorEmbedTagSelectData = void;

export type ModalPostEditorEmbedTagSelect = ReactiveVar<
  Modal<ModalPostEditorEmbedTagSelectData>
>;

export type UsernameSubmitForm = {
  url: string;
};

const EmbedTagSelectModal = () => {
  const { showModal } = useReactiveVar(
    modalVar as ModalPostEditorEmbedTagSelect
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const validationSchema = yupObject({
    url: yupString()
      .matches(urlRegex, "Please enter a valid URL.")
      .required("Please enter an URL."),
  }).required();

  const onSubmit = async (formData: UsernameSubmitForm) => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await fetchEmbed(formData.url);
      if (res.ok && res.status >= 200 && res.status < 300) {
        dispatchEmbedInsert(formData.url);
        resetField("url");
      } else {
        const body = res.json();
        await logError({
          message: `Error validating embed. Response body: ${body}`,
        });
        setErrorMessage("Error validating embed.");
      }
    } catch (e) {
      setErrorMessage("Error fetching embed API.");
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<UsernameSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <ModalBase
      title="Embed Tag"
      isOpen={showModal === ModalType.EDITOR_EMBED_SELECT}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Url to embed"
            placeholder="https://"
            extraCss={styles.usernameInput}
            {...register("url")}
            error={errors.url?.message || errorMessage}
          />
          <Button size="xl" type="submit" fullWidth loading={loading}>
            Add
          </Button>
        </form>
      </div>
    </ModalBase>
  );
};

export default memo(EmbedTagSelectModal);

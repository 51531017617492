import { css } from "@emotion/react";
import { topMargin } from "../../../styles/global/layout";
import { textMediumStyles } from "../../../styles/global/typography";
export const fieldGroup = css ``;
export const label = css `
  display: block;
`;
export const input = (error) => (theme) => css `
    display: block;
    appearance: none;
    width: 100%;
    outline: none;
    padding: 16px;
    margin: 0;
    ${textMediumStyles(theme)}
    border-radius: ${theme.textInput.border.radius};
    border-width: ${error ? "2px" : `${theme.textInput.border.width}`};
    border-style: ${error ? "solid" : `${theme.textInput.border.style}`};
    border-color: ${error
    ? `${theme.colorMap.destructive.default}`
    : `${theme.textInput.border.color}`};
    background-color: ${theme.textInput.backgroundColor};
    color: ${theme.textInput.textColor};
    font-family: ${theme.text.fontFamily.primary};
    font-feature-settings: "calt" off;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    ${topMargin("5/12px")}
    &::placeholder {
      opacity: initial; //Remove if we stop using sanitize css
      color: ${theme.textInput.placeholderColor};
    }

    ${theme.mediaQueries.mediumUp} {
      padding: 20px 16px;
    }
  `;
export const description = css ``;
export const error = (theme) => css `
  ${textMediumStyles(theme)}
  color: ${theme.colorMap.destructive.default};
  padding: 0;
  margin: 8px 0 0 0;
`;

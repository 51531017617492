import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import LoadingIcon from "../LoadingIcon";
import * as styles from "./styles";
const SystemButton = ({ onClick, disabled, loading, children, className = "", size = "large", color = "primary", hasBorder = true, fluid = true, extraCss, additionalCss, ...rest }) => (_jsxs("button", { onClick: !loading ? onClick : undefined, disabled: loading || disabled, className: className, css: [
        loading && styles.loading,
        styles.button(color, size, fluid, hasBorder),
        additionalCss,
        extraCss,
    ], ...rest, children: [_jsx("span", { css: styles.buttonText, children: children }), loading && _jsx(LoadingIcon, { css: styles.icon })] }));
export const NewAppButton = ({ onClick, disabled, loading, children, color = "primary", additionalCss, ...rest }) => (_jsxs("button", { onClick: !loading ? onClick : undefined, disabled: loading || disabled, css: [styles.newAppButton(color, loading), additionalCss], ...rest, children: [children, loading && _jsx(LoadingIcon, {})] }));
export default memo(SystemButton);

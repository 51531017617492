import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Youtube as VideoIcon } from "../../../../../../assets/icons.generated";
import { AttachmentType, SupportedMediaType, } from "../../../types";
import { INSERT_VIDEO_COMMAND } from "../../VideoAttachmentPlugin";
import BaseFileAttachment from "../BaseFileAttachment";
const VideoAttachment = ({ handleFileSelected }) => {
    const [activeEditor] = useLexicalComposerContext();
    const insertVideo = (attachment, fileName) => {
        activeEditor.focus();
        activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, {
            attachment,
            fileName,
        });
    };
    return (_jsx(BaseFileAttachment, { title: "Video", id: "LexicalEditorVideoFileUpload", type: "file", accept: "video/*", onChange: handleFileSelected(SupportedMediaType.VIDEO, AttachmentType.MEDIA, insertVideo), children: _jsx(VideoIcon, {}) }));
};
export default memo(VideoAttachment);

import { defaultTheme as communitiesDefaultTheme } from "@hl/communities-app/lib/styles/theme/theme";
import {
  Background,
  Colors,
  Text,
  InvertedText,
} from "@hl/communities-app/lib/styles/theme/theme.types";

import {
  BackgroundType,
  CornersShape,
  PageTheme,
} from "apollo/graphql.generated";

import { LegacyTheme, CustomTheme } from "./theme.types";

const rootFontSize = 16;

const background: Background = {
  colors: {
    primary: "#ffffff",
    secondary: "#F8F7F6",
    tertiary: "#E2E1E0",
    quaternary: "#C5C3C1",
  },
  imageUrl: undefined as undefined | string,
};

const invertedBackground: Background = {
  colors: {
    primary: "#0d0d0d",
    secondary: "#2b2b2b",
    tertiary: "#454545",
    quaternary: "#5e5e5e",
  },
};
const colorMap: Colors = {
  utility: {
    divider: "rgba(2, 2, 2, 0.1)",
    warning: "#F28C00",
    warningWash: "#FFF2E0",
  },
  vendor: {
    twitterBlue: "#4999E9",
    facebookBlue: "#1877F2",
  },
  accent: {
    primary: "#000000",
    secondary: "#9FA3FF",
    tertiary: "#F3F4FF",
    dark: "#222183",
    50: "#EBEBFF",
    100: "#BFBDFF",
    200: "#8D8AFF",
    300: "#5C57FF",
    500: "#2B24FF",
    600: "#0700D6",
    default: "",
  },
  destructive: {
    50: "#FCE9E9",
    100: "#F9D2D2",
    200: "#F2A6A6",
    300: "#EC7979",
    500: "#DF2020",
    600: "#B21A1A",
    default: "",
  },
  affirmative: {
    primary: "#00C855",
    secondary: "#7FFF9D",
    tertiary: "#D5FFDB",
    dark: "#007E33",
    50: "#ECF9EF",
    100: "#D9F2E0",
    200: "#B3E5C1",
    300: "#8CD9A2",
    500: "#40BF63",
    600: "#339950",
    default: "",
  },
};

colorMap.accent.default = colorMap.accent[500];
colorMap.destructive.default = colorMap.destructive[500];
colorMap.affirmative.default = colorMap.affirmative[500];

const text: Text = {
  colors: {
    primary: "#0d0d0d",
    secondary: "hsla(0, 0%, 0%, .6)",
    tertiary: "hsla(0, 0%, 0%, .3)",
    onBackground: {
      primary: "#0d0d0d",
      secondary: "hsla(0, 0%, 0%, .6)",
    },
    onSecondaryBackground: {
      primary: "#0d0d0d",
      secondary: "hsla(0, 0%, 0%, .6)",
    },
  },
  lineHeights: {
    xsmall: "1.4",
    small: "1.4",
    medium: "1.4",
    large: "1.4",
    xlarge: "1.4",
  },
  fontFamily: {
    primary: "Aeonik Pro, sans-serif",
    secondary: "Aeonik Pro, sans-serif",
  },
  sizes: {
    xsmall: "0.75rem",
    small: "0.75rem",
    medium: "0.875rem",
    large: "1rem",
    xlarge: "1.125rem",
  },
  fontWeights: { normal: 400, bold: 500 },
  letterSpacing: {
    xsmall: "0.02em",
    small: "0.02em",
    medium: "0.02em",
    large: "0.02em",
    xlarge: "0.02em",
  },
  textTransform: "none",
};

const invertedText: InvertedText = {
  colors: {
    primary: "#ffffff",
    secondary: "hsla(0, 0%, 100%, .6)",
    tertiary: "hsla(0, 0%, 100%, .3)",
  },
};

const theme: LegacyTheme = {
  background,
  invertedBackground,
  overlay: "hsla(0, 0%, 0%, .8)",
  divider: "hsla(0, 0%, 0%, .1)",
  colorMap,
  rootFontSize: `${rootFontSize}px`,
  text,
  invertedText,
  modal: {
    borderRadius: "20px",
    padding: {
      small: "24px",
      medium: "32px 40px 40px 40px",
    },
  },
  cardContainer: {
    borderRadius: {
      medium: "10px",
      large: "20px",
    },
    padding: {
      medium: "16px",
      large: "20px",
    },
  },
  label: {
    fontFamily: "'Aeonik Pro', sans-serif",
    letterSpacing: "0.1em",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  title: {
    sizes: {
      xsmall: "1.5rem",
      small: "2rem",
      medium: "2.5rem",
      large: "3.0rem",
      xlarge: "3.5rem",
      smallScreen: {
        xsmall: "1.5rem",
        small: "2rem",
        medium: "2.25rem",
        large: "2.5rem",
        xlarge: "2.5rem",
      },
    },
    lineHeights: {
      xsmall: "1.2",
      small: "1.2",
      medium: "1.2",
      large: "1.2",
      xlarge: "1.2",
      smallScreen: {
        xsmall: "1.2",
        small: "1.2",
        medium: "1.2",
        large: "1.2",
        xlarge: "1.2",
      },
    },
    fontWeight: 500,
    fontFamily: "'Aeonik Pro', sans-serif",
    letterSpacing: {
      xsmall: "0",
      small: "0",
      medium: "0",
      large: "0",
      xlarge: "0",
    },
    textTransform: "none",
  },
  textInput: {
    backgroundColor: background.colors.primary,
    textColor: text.colors.primary,
    placeholderColor: text.colors.secondary,
    border: {
      color: "rgba(0, 0, 0, 0.1)",
      style: "solid",
      width: "1px",
      radius: "6px",
    },
  },
  buttons: {
    primary: {
      background: "linear-gradient(91.02deg, #8900ca 0%, #d272ff 100%)",
      backgroundColor: "#8900ca",
      textColor: "#FFFFFF",
      border: {
        color: text.colors.primary,
        style: "solid",
        width: "0px",
        radius: "6px",
      },
    },
    secondary: {
      backgroundColor: "white",
      textColor: text.colors.primary,
      border: {
        color: "#E7E7E7",
        style: "solid",
        width: "1px",
        radius: "6px",
      },
    },
    tertiary: {
      backgroundColor: invertedBackground.colors.primary,
      textColor: invertedText.colors.primary,
      border: {
        color: "#000000",
        style: "solid",
        width: "0px",
        radius: "6px",
      },
    },
    text: {
      fontFamily: text.fontFamily.primary,
      fontWeight: text.fontWeights.bold,
      letterSpacing: text.letterSpacing.medium,
      textTransform: text.textTransform,
    },
    sizes: {
      xl: {
        padding: "20px 30px",
        headerPadding: "11px 30px",
        fontSize: "16px",
      },
      large: {
        padding: "16px 30px",
        headerPadding: "7px 30px",
        fontSize: "16px",
      },
      medium: {
        padding: "12px 30px",
        fontSize: "16px",
      },
      small: {
        padding: "8px 16px",
        fontSize: "14px",
      },
    },
  },
  verticalSpaces: communitiesDefaultTheme.verticalSpaces,
  layout: communitiesDefaultTheme.layout,
  dividers: {
    top: "inset 0px 0.5px 0px rgba(0, 0, 0, 0.1)",
    right: "inset -0.5px 0px 0px rgba(0, 0, 0, 0.1)",
    bottom: "inset 0px -0.5px 0px rgba(0, 0, 0, 0.1)",
    left: "inset 0.5px 0px 0px rgba(0, 0, 0, 0.1)",
    vertical:
      "inset 0px 0.5px 0px rgba(0, 0, 0, 0.1), inset 0px -0.5px 0px rgba(0, 0, 0, 0.1)",
    horizontal:
      "inset 0.5px 0px 0px rgba(0, 0, 0, 0.1), inset -0.5px 0px 0px rgba(0, 0, 0, 0.1)",
  },
  // TODO: make it to initialize with mantine `breakpoints`
  // https://linear.app/highlight-xyz/issue/ENG-3359/theme-provider-refactor
  mediaQueries: communitiesDefaultTheme.mediaQueries,
  animations: {
    easeOutQuart: "cubic-bezier(0.22, 1, 0.36, 1)",
  },
  zLayers: {
    loading: 100,
    tokenDetails: 30,
    modal: 20,
    navBar: 9,
    editor: 8,
  },
};

export const defaultPageTheme: PageTheme = {
  background: {
    type: BackgroundType.Solid,
    color: "#FFFFFF",
  },
  text: {
    fontFamily: "Inter",
    color: "#000000",
  },
  title: {
    fontFamily: "Inter",
  },
  corners: {
    shape: CornersShape.Rounded,
  },
};

export const defaultTheme: CustomTheme = {
  ...theme,
  page: {
    background: {
      type: BackgroundType.Solid,
      color: "#FFFFFF",
    },
    text: {
      fontFamily: theme.text.fontFamily.primary,
      color: "#000000",
    },
    title: {
      fontFamily: theme.title.fontFamily,
    },
    corners: {
      shape: CornersShape.Rounded,
    },
  },
};

import { FC, memo, ComponentProps, ReactNode } from "react";

import { Interpolation, Theme } from "@emotion/react";
import LayoutBar from "@hl/communities-app/lib/components/LayoutBar";

import { Background } from "../../../components/blocks/Background";
import Footer from "../Footer";
import NavBar, { NavBarProps } from "../NavBar";

import * as styles from "./styles";

type MainProps = ComponentProps<"main">;

export type LayoutProps = MainProps & {
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  navLayout?: NavBarProps["layout"];
  lightNavBarContent?: boolean;
  extraButtons?: ReactNode;
  header?: ReactNode;
  extraCss?: Interpolation<Theme>;
  logoLinkTo?: string;
};

const Layout: FC<LayoutProps> = ({
  showHeader = true,
  navLayout = "list",
  showFooter = true,
  children,
  extraButtons,
  header,
  extraCss,
  logoLinkTo,
  ...rest
}) => {
  return (
    <Background>
      <LayoutBar sticky={navLayout !== "create"}>
        {showHeader && (
          <NavBar
            logoLinkTo={logoLinkTo}
            sticky={navLayout !== "create"}
            layout={navLayout}
            extraButtons={extraButtons}
            header={header}
          />
        )}
      </LayoutBar>
      <main css={[styles.main, extraCss]} {...rest}>
        {children}
      </main>
      {showFooter && (
        <Footer
          showTooltip={navLayout === "page"}
          usePageTheme={navLayout === "page"}
        />
      )}
    </Background>
  );
};

export default memo(Layout);

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { css } from "@emotion/react";
import Text from "../../../Typography/Text";
const styles = {
    button: (color) => (theme) => css `
      background: white;
      box-shadow: ${theme.shadows["sm"]};
      border-radius: 99px;
      padding: 4px 12px;
      color: ${theme.colorMap[color].default};

      span {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: auto;
        color: inherit;
        margin-left: 5px;
      }
    `,
};
const AttachmentFloatingButton = ({ icon, color = "accent", children, ...rest }) => (_jsxs("button", { css: styles.button(color), ...rest, children: [icon, _jsx(Text, { as: "span", bold: true, size: "small", children: children })] }));
export default memo(AttachmentFloatingButton);

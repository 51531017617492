import { css } from "@emotion/react";
import { baseBackgroundTextColor, baseTextColor, innerHTML, textStyles, } from "../../../styles/global/typography";
export const bySize = (size, bold, uppercase) => (theme) => css `
      ${textStyles(size, bold)(theme)}
      ${uppercase ? "text-transform: uppercase" : null}
    `;
export const byColor = (color, inverted) => baseTextColor(color, inverted);
export const byBackgroundTextColor = (color) => baseBackgroundTextColor(color);
export const byInnerHTML = innerHTML("system");
export const textAlign = (textAlign) => css `
  text-align: ${textAlign};
`;
export const presLineBreaks = css `
  white-space: pre-wrap;
`;

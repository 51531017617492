import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { $createVideoNode, VideoNode } from "../nodes/VideoNode";
export const INSERT_VIDEO_COMMAND = createCommand();
export default function VideoAttachmentPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([VideoNode])) {
            throw new Error("VideoPlugin: VideoNode not registered on editor");
        }
        return editor.registerCommand(INSERT_VIDEO_COMMAND, (payload) => {
            var _a;
            const videoNode = $createVideoNode((_a = payload.attachment) === null || _a === void 0 ? void 0 : _a.metadata, payload.fileName);
            $insertNodeToNearestRoot(videoNode);
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}
